import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Pages
import ComingSoon from "./pages/landing-page/landing-page";
import Login from "./pages/login/login";
import ScrapGenerators from "./pages/scrap-generators/scrap-generators";
import { AuthProvider } from "./contexts/auth-context";
import ScrapDealers from "./pages/scrap-dealers/scrap-dealers";
import NewScrapDealerForm from "./pages/scrap-dealers/components/new-scrap-dealer-form/new-scrap-dealer-form";
import { AlertProvider } from "./contexts/alert-context";
import ScrapDealerDetails from "./pages/scrap-dealers/components/scrap-dealer-details/scrap-dealer-details";
import ScrapDealerDetailsRegister from "./pages/scrap-dealers/components/scrap-dealer-details-register/scrap-dealer-details-register";
import ScrapDealerDetailsBranchs from "./pages/scrap-dealers/components/scrap-dealer-details-branchs/scrap-dealer-details-branchs";
import NewScrapGeneratorForm from "./pages/scrap-generators/components/new-scrap-generator-form/new-scrap-generator-form";
import ScrapGeneratorDetailsBranchs from "./pages/scrap-generators/components/scrap-generator-details/components/scrap-generator-details-branchs/scrap-generator-details-branchs";
import ScrapGeneratorDetailsRegister from "./pages/scrap-generators/components/scrap-generator-details/components/scrap-generator-details-register/scrap-generator-details-register";
import ScrapGeneratorDetails from "./pages/scrap-generators/components/scrap-generator-details/scrap-generator-details";
import ScrapAdminn from "./pages/scraps/scrap-admin-generator-view/scrap-admin";
import Settings from "./pages/settings/settings";
import Contacts from "./pages/contacts/contacts";
import NotFound from "./components/NotFound/NotFound";
import Logout from "./components/Logout/Logout";
import Users from "./pages/settings/components/users/users";
import EmailsWarnings from "./pages/settings/components/Emails-warning/emails-warning";
import Changelog from "./pages/settings/components/Changelog/changelog";
import PartnerRequirement from "./pages/settings/components/partners-requirements/partner-requirement";
import ContainerTypes from "./pages/settings/components/Containers-types/container-types";
import Banks from "./pages/settings/components/Banks/banks";
import PartnerRequirementCheck from "./pages/scrap-dealers/components/partners-requirement-check/partner-requirement-check";
import ScrapForm from "./pages/scraps/scrap-admin-generator-view/components/scrap-form/scrap-form";
import CountryConfig from "./pages/settings/components/Country-config/country-config";
import CountryConfigForm from "./pages/settings/components/Country-config/country-config-form/country-config-form";
import ScrapCategoryForm from "./pages/scraps/scrap-category/scrap-category-form/scrap-category-form";
import ScrapCategoryDetails from "./pages/scraps/scrap-category/scrap-details/scrap-details";
import ScrapAdminScrapsToDealView from "./pages/scraps/scrap-admin-scraps-to-deal-view/scrap-admin-scraps-to-deal-view";
import ScrapTab from "./pages/scraps/scrap-tab/scrap-tab";
import ScrapCategories from "./pages/scraps/scrap-category/scrap-categories";
import ScrapAdminScrapsCategoryToDealView from "./pages/scraps/scrap-admin-scraps-category-to-deal-view/scrap-admin-scraps-category-to-deal-view";
import ScrapAdminDealerView from "./pages/scraps/scrap-admin-dealer-view/scrap-admin-dealer-view";
import ScrapAdminScrapsContractedView from "./pages/scraps/scrap-admin-scraps-contracted-view/scrap-admin-scraps-contracted-view";
import CreditAnalysis from "./pages/scrap-dealers/components/credit-analysis/credit-analysis";
import ScrapAdminScrapsCategoryDealerView from "./pages/scraps/scrap-admin-scraps-category-dealer-view/scrap-admin-scraps-category-dealer-view";
import ScrapAdminScrapsCategoryGeneratorView from "./pages/scraps/scrap-admin-scraps-category-generator-view/scrap-admin-scraps-category-generator-view";
import Dashboard from "./pages/dashboard/dashboard";
import ScrapGeneratorScraps from "./pages/scraps/scrap-generator-scraps-view/scrap-generator-scraps";
import ScrapGeneratorScrapsForm from "./pages/scraps/scrap-generator-scraps-view/components/scrap-form/scrap-generator-scraps-form";
import ScrapGeneratorApproveRoll from "./pages/scrap-generators/components/scrap-generator-details/components/scrap-generator-approve-roll/scrap-generator-approve-roll";
import SpecialTechnologies from "./pages/scrap-dealers/components/special-technologies/special-technologies";
import SocialActivities from "./pages/scrap-dealers/components/social-activities/social-activities";
import Collects from "./pages/collects/collects";
import ScrapAdminScrapsInBidView from "./pages/scraps/scrap-admin-scraps-in-bid-view/scrap-admin-scraps-in-bid-view";
import ScrapAdminScrapsCategoryInBidView from "./pages/scraps/scrap-admin-scraps-category-in-bid-view/scrap-admin-scraps-category-in-bid-view";
import ScrapAdminScrapsCategoryContractedView from "./pages/scraps/scrap-admin-scraps-category-contracted-view/scrap-admin-scraps-category-contracted-view";
import FinancialTab from "./pages/financial/financial-tab/financial-tab";
import FinancialToCollectView from "./pages/financial/financial-to-collect-view/financial-to-collect-view";
import FinancialToReceiveView from "./pages/financial/financial-to-receive-view/financial-to-receive-view";
import FinancialReceivedView from "./pages/financial/financial-received-view/financial-received-view";
import Vehicles from "./pages/vehicles/vehicles";
import Drivers from "./pages/drivers/drivers";
import Documents from "./pages/documents/documents";
import Optimization from "./pages/Optimization/optimization";
import ScrapAdminScrapsInApprovalView from "./pages/scraps/scrap-admin-scraps-in-approval-view/scrap-admin-scraps-in-approval-view";
import ScrapAdminScrapsCategoryInApprovalView from "./pages/scraps/scrap-admin-scraps-category-in-approval-view/scrap-admin-scraps-category-in-approval-view";
import ScrapAdminScrapsReprovedView from "./pages/scraps/scrap-admin-scraps-reproved-view/scrap-admin-scraps-reproved-view";
import ScrapGroups from "./pages/scraps/scrap-group/scrap-groups";
import ScrapCategoryPanel from "./pages/scraps/scrap-category/scrap-details/components/scrap-category-pannel";
import ScrapGroupDetails from "./pages/scraps/scrap-group/scrap-group-details/scrap-group-details";

import ScrapCategoryPannel from "./pages/scraps/scrap-category/scrap-details/components/scrap-category-pannel";
import ScrapGroupMaterials from "./pages/scraps/scrap-group/scrap-group-details/components/scrap-group-materials";
import SuccessFee from "./pages/scrap-generators/components/scrap-generator-details/components/success-fee/success-fee";
import GeneratorSettings from "./pages/generator-settings/generator-settings";
import Sigor from "./pages/generator-settings/components/sigor/sigor";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />, // ROTA PRINCIPAL
    children: [
      {
        path: "/",
        element: <ComingSoon />, // ROTA PUBLICA
      },
      {
        path: "/login",
        element: <Login />, // ROTA PUBLICA
      },
      {
        path: "/dashboard",
        element: (
          <Dashboard enabledUsers={["admin-bosch", "scrap-generator"]} />
        ),
      },

      {
        path: "/settings/:id",
        element: <Settings enabledUsers={["admin-bosch"]} />,
        children: [
          {
            path: "/settings/:id/users",
            element: (
              <Users
                enabledUsers={["admin-bosch"]}
                importApi="importusersboschcsv"
                exportApi="exportusersboschcsv"
              />
            ),
          },
          {
            path: "/settings/:id/emails-warning",
            element: <EmailsWarnings enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/settings/:id/changelog",
            element: <Changelog enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/settings/:id/partners-requirements",
            element: <PartnerRequirement enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/settings/:id/container-types",
            element: <ContainerTypes enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/settings/:id/banks",
            element: <Banks enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/settings/:id/country-config",
            element: <CountryConfig enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/settings/:id/country-config/:countryId/edit",
            element: <CountryConfigForm enabledUsers={["admin-bosch"]} />,
          },
        ],
      },

      {
        path: "/generator-settings",
        element: <GeneratorSettings enabledUsers={["scrap-generator"]} />,
        children: [
          {
            path: "/generator-settings/SIGOR",
            element: <Sigor enabledUsers={["scrap-generator"]} />,
          },
        ],
      },

      {
        path: "/country-config-form",
        element: <CountryConfigForm enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/logout",
        element: <Logout />, // ROTA PUBLICA
      },

      {
        path: "/scrap-generators",
        element: <ScrapGenerators enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-generators-new",
        element: <NewScrapGeneratorForm enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-generators-new/:headquarterParamId",
        element: <NewScrapGeneratorForm enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-generators-details/:id",
        element: <ScrapGeneratorDetails enabledUsers={["admin-bosch"]} />,
        children: [
          {
            path: "/scrap-generators-details/:id/approve-roll",
            element: (
              <ScrapGeneratorApproveRoll enabledUsers={["admin-bosch"]} />
            ),
          },
          {
            path: "/scrap-generators-details/:id/documents",
            element: (
              <Documents enabledUsers={["admin-bosch", "scrap-generator"]} />
            ),
          },
          {
            path: "/scrap-generators-details/:id/fee",
            element: (
              <SuccessFee enabledUsers={["admin-bosch"]} />
            ),
          },
          {
            path: "/scrap-generators-details/:id/register",
            element: (
              <ScrapGeneratorDetailsRegister enabledUsers={["admin-bosch"]} />
            ),
          },
          {
            path: "/scrap-generators-details/:id/dealers",
            element: <ScrapDealers enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-generators-details/:id/branchs",
            element: (
              <ScrapGeneratorDetailsBranchs enabledUsers={["admin-bosch"]} />
            ),
          },
          {
            path: "/scrap-generators-details/:id/collections-generator",
            element: <Collects enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-generators-details/:id/contacts",
            element: <Contacts enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-generators-details/:id/optimization",
            element: <Optimization enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-generators-details/:id/sigor",
            element: <Sigor enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-generators-details/:id/scraps",
            element: <ScrapAdminn enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-generators-details/:id/scraps/:r",
            element: <ScrapAdminn enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-generators-details/:id/scraps-form",
            element: <ScrapForm enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-generators-details/:id/scraps-form/:scrapId",
            element: <ScrapForm enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-generators-details/:id/users",
            element: (
              <Users
                importApi="importusersscrapgeneratorscsv"
                exportApi="exportusersscrapgeneratorscsv"
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
        ],
      },
      {
        path: "/scrap-generators-edit/:id",
        element: <NewScrapGeneratorForm enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-dealers",
        element: (
          <ScrapDealers enabledUsers={["admin-bosch", "scrap-generator"]} />
        ),
      },
      {
        path: "/scrap-dealers-edit/:id",
        element: <NewScrapDealerForm enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-dealers-new",
        element: <NewScrapDealerForm enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-dealers-new/:headquarterParamId",
        element: <NewScrapDealerForm enabledUsers={["admin-bosch"]} />,
      },

      {
        path: "/scrap-dealers-details/:id",
        element: (
          <ScrapDealerDetails
            enabledUsers={["admin-bosch", "scrap-generator"]}
          />
        ),
        children: [
          {
            path: "/scrap-dealers-details/:id/register",
            element: (
              <ScrapDealerDetailsRegister
                enabledUsers={["admin-bosch", "scrap-generator"]}
              />
            ),
          },
          {
            path: "/scrap-dealers-details/:id/collections-dealer",
            element: <Collects enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-dealers-details/:id/branchs",
            element: (
              <ScrapDealerDetailsBranchs enabledUsers={["admin-bosch"]} />
            ),
          },

          {
            path: "/scrap-dealers-details/:id/contacts",
            element: <Contacts enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-dealers-details/:id/vehicles",
            element: (
              <Vehicles enabledUsers={["admin-bosch", "scrap-generator"]} />
            ),
          },
          {
            path: "/scrap-dealers-details/:id/drivers",
            element: (
              <Drivers enabledUsers={["admin-bosch", "scrap-generator"]} />
            ),
          },
          {
            path: "/scrap-dealers-details/:id/documents",
            element: (
              <Documents enabledUsers={["admin-bosch", "scrap-generator"]} />
            ),
          },
          {
            path: "/scrap-dealers-details/:id/specialTechnologies",
            element: (
              <SpecialTechnologies
                enabledUsers={["admin-bosch", "scrap-generator"]}
              />
            ),
          },
          {
            path: "/scrap-dealers-details/:id/socialActivities",
            element: (
              <SocialActivities
                enabledUsers={["admin-bosch", "scrap-generator"]}
              />
            ),
          },
          {
            path: "/scrap-dealers-details/:id/scraps",
            element: (
              <ScrapAdminDealerView
                enabledUsers={["admin-bosch", "scrap-generator"]}
              />
            ),
          },
          {
            path: "/scrap-dealers-details/:id/users",
            element: (
              <Users
                importApi="importusersscrapdealerscsv"
                exportApi="exportusersscrapdealerscsv"
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
          {
            path: "/scrap-dealers-details/:id/credit-analysis",
            element: <CreditAnalysis enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-dealers-details/:id/requirement-check",
            element: (
              <PartnerRequirementCheck
                enabledUsers={["admin-bosch", "scrap-generator"]}
              />
            ),
          },
        ],
      },
      {
        path: "/scrap-categories-form",
        element: <ScrapCategoryForm enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-categories-form/:generatorId/:scrapCategoryName?",
        element: (
          <ScrapCategoryForm
            enabledUsers={["admin-bosch", "scrap-generator"]}
          />
        ),
      },
      {
        path: "/scraps",
        element: <ScrapTab enabledUsers={["admin-bosch"]} />,
        children: [
          {
            path: "/scraps/scraps-group",
            element: <ScrapGroups enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scraps/scraps-category",
            element: <ScrapCategories enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scraps/scraps-to-deal",
            element: (
              <ScrapAdminScrapsToDealView enabledUsers={["admin-bosch"]} />
            ),
          },
          {
            path: "/scraps/scraps-in-bid",
            element: (
              <ScrapAdminScrapsInBidView enabledUsers={["admin-bosch"]} />
            ),
          },
          {
            path: "/scraps/scraps-in-approval",
            element: (
              <ScrapAdminScrapsInApprovalView enabledUsers={["admin-bosch"]} />
            ),
          },
          {
            path: "/scraps/contracts",
            element: (
              <ScrapAdminScrapsContractedView enabledUsers={["admin-bosch"]} />
            ),
          },
          {
            path: "/scraps/scraps-inactive",
            element: (
              <ScrapAdminScrapsReprovedView enabledUsers={["admin-bosch"]} />
            ),
          },
        ],
      },
      {
        path: "/collects",
        element: <Collects enabledUsers={["admin-bosch", "scrap-generator"]} />,
      },
      {
        path: "/users/:id",
        element: <Users enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-categories-details/:id",
        element: <ScrapCategoryDetails enabledUsers={["admin-bosch"]} />,
        children: [
          {
            path: "/scrap-categories-details/:id/edit",
            element: <ScrapCategoryForm enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-categories-details/:id/register",
            element: <ScrapCategoryForm enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-categories-details/:id/pannel",
            element: <ScrapCategoryPanel enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-categories-details/:id/to-deal/",
            element: (
              <ScrapAdminScrapsCategoryToDealView
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
          {
            path: "/scrap-categories-details/:id/in-bid/",
            element: (
              <ScrapAdminScrapsCategoryInBidView
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
          {
            path: "/scrap-categories-details/:id/in-approval/",
            element: (
              <ScrapAdminScrapsCategoryInApprovalView
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
          {
            path: "/scrap-categories-details/:id/contracted/",
            element: (
              <ScrapAdminScrapsCategoryContractedView
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
          {
            path: "/scrap-categories-details/:id/generators",
            element: (
              <ScrapAdminScrapsCategoryGeneratorView
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
          {
            path: "/scrap-categories-details/:id/dealers",
            element: (
              <ScrapAdminScrapsCategoryDealerView
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
        ],
      },
      {
        path: "/scrap-groups-details/:id",
        element: <ScrapGroupDetails enabledUsers={["admin-bosch"]} />,
        children: [
          {
            path: "/scrap-groups-details/:id/pannel",
            element: <ScrapCategoryPannel enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-groups-details/:id/materials",
            element: <ScrapGroupMaterials enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-groups-details/:id/to-deal/",
            element: (
              <ScrapAdminScrapsCategoryToDealView
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
          {
            path: "/scrap-groups-details/:id/in-bid/",
            element: (
              <ScrapAdminScrapsCategoryInBidView
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
          {
            path: "/scrap-groups-details/:id/in-approval/",
            element: (
              <ScrapAdminScrapsCategoryInApprovalView
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
          {
            path: "/scrap-groups-details/:id/contracted/",
            element: (
              <ScrapAdminScrapsCategoryContractedView
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
          {
            path: "/scrap-groups-details/:id/generators",
            element: (
              <ScrapAdminScrapsCategoryGeneratorView
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
          {
            path: "/scrap-groups-details/:id/dealers",
            element: (
              <ScrapAdminScrapsCategoryDealerView
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
      {
        path: "/financial",
        element: (
          <FinancialTab enabledUsers={["admin-bosch", "scrap-generator"]} />
        ),
        children: [
          {
            path: "/financial/financial-to-collect",
            element: (
              <FinancialToCollectView
                enabledUsers={["admin-bosch", "scrap-generator"]}
              />
            ),
          },
          {
            path: "/financial/financial-to-receive",
            element: (
              <FinancialToReceiveView
                enabledUsers={["admin-bosch", "scrap-generator"]}
              />
            ),
          },
          {
            path: "/financial/financial-received",
            element: (
              <FinancialReceivedView
                enabledUsers={["admin-bosch", "scrap-generator"]}
              />
            ),
          },
        ],
      },
      {
        path: "/generator-scraps/:id/generator-scraps-form",
        element: (
          <ScrapGeneratorScrapsForm enabledUsers={["scrap-generator"]} />
        ),
      },
      {
        path: "/generator-scraps/:id/generator-scraps-form/:scrapId",
        element: (
          <ScrapGeneratorScrapsForm enabledUsers={["scrap-generator"]} />
        ),
      },
      {
        path: "/generator-scraps/:alertMessage",
        element: <ScrapGeneratorScraps enabledUsers={["scrap-generator"]} />,
      },
      {
        path: "/generator-scraps/",
        element: <ScrapGeneratorScraps enabledUsers={["scrap-generator"]} />,
        children: [
          {
            path: "/generator-scraps/:id/scraps-form",
            element: (
              <ScrapGeneratorScrapsForm enabledUsers={["scrap-generator"]} />
            ),
          },
          {
            path: "/generator-scraps/:id/scraps-form/:scrapId",
            element: (
              <ScrapGeneratorScrapsForm enabledUsers={["scrap-generator"]} />
            ),
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <AlertProvider>
        <RouterProvider router={router} />
      </AlertProvider>
    </AuthProvider>
  </React.StrictMode>
);
