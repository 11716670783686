import React from "react";
import { ChecklistItem } from "./models/checklist-item-DTO";
import { useNavigate } from "react-router-dom";

interface ChecklistProps {
  items: ChecklistItem[];
  title?: string;
  children?: React.ReactNode;
}

const ChecklistGenericComponent: React.FC<ChecklistProps> = ({
  items,
  title,
  children,
}) => {
  const allCompleted = items.every((item) => item.checked);
  const navigate = useNavigate();

  return (
    <div>
      {allCompleted ? (
        <div>{children}</div>
      ) : (
        <div
          className="info-section"
          style={{
            padding: "1rem",
            borderTop: "5px solid var(--bosch-accent-purple)",
          }}
        >
          <h3>{title}:</h3>
          <div>
            <ul
              className="flex-column"
              style={{
                border: "none",
                gap: 2,
                padding: 0,
                margin: 0,
                maxHeight: "none",
                height: "auto",
                overflow: "visible",
              }}
            >
              {items.map((item) => (
                <li
                  key={item.text}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "var(--white-color)",
                  }}
                >
                  <a
                    onClick={() => {
                      if (item.link) {
                        navigate(item.link);
                      }
                    }}
                    style={{
                      flex: 1,
                      textDecoration: item.checked ? "line-through" : "none",
                      color: item.checked
                        ? "var(--secondary-text-color)"
                        : item.link
                        ? "var(--secondary-color)"
                        : "var(--text-color)",
                      cursor:
                        item.link && !item.checked ? "pointer" : "default",
                    }}
                  >
                    - {item.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChecklistGenericComponent;
