import React, { useState, useRef, useEffect } from "react";
import { t } from "i18next";

import { CurrencyInput } from "react-currency-mask";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { firestore, storage } from "../../../../../../utils/firebase-config";
import { sendDealerEmail } from "../../../../scrap-admin-service";
import { useAlert } from "../../../../../../contexts/alert-context";
import { extractFileNameFromUrl } from "../../../../../../services/string-treatment";
import {
  SparkIcon,
  SparkTableBody,
  SparkTableCell,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import { DealersInvitedTableDTO } from "../../../../scrap-generator-scraps-view/components/scrap-form/models/dealers-invited-DTO";
import { ScrapDTO } from "../models/scrap-DTO";
import { update } from "../../../../../../services/persistence";
import { COLLECTIONS } from "../../../../../../assets/enums/firebase-colections";

interface ModalProps {
  onClose: (updatedData?: DealersInvitedTableDTO, submitType?: string) => void;
  tableData: DealersInvitedTableDTO;
  countryConfigEntity: any;
  id: string;
  scrapData?: ScrapDTO;
}

enum Status {
  INVITE_ACCEPTED = "INVITE_ACCEPTED",
  PARTICIPANT = "PARTICIPANT",
  WINNER = "WINNER",
  ABANDONED = "ABANDONED",
  NOT_APPROVED = "NOT_APPROVED",
}

const ScrapDealersInvitedModalForm: React.FC<ModalProps> = ({
  onClose,
  tableData,
  countryConfigEntity,
  id,
  scrapData
}) => {
  const [formData, setFormData] = useState<DealersInvitedTableDTO>(tableData);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [submitType, setSubmitType] = useState<string | null>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);
    }
  };

  const { setAlert } = useAlert();

  useEffect(() => {
    if (submitType === "sendInvite") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        inviteSended: true,
      }));
    }
  }, [submitType]);

  useEffect(() => {
    if (submitType === "sendInvite" && formData.inviteSended) {
      formRef.current?.requestSubmit();
    }
  }, [formData.inviteSended]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (submitType === "sendInvite") {
      const language: string =
        countryConfigEntity.locale.split("-")[0] == "pt" ? "pt" : "en";
      sendDealerEmail(id, tableData.id, language)


      setAlert({
        text: t("label-invite-sended"),
        type: "success",
      });
    }

    const fileURLs: string[] = [];
    for (const file of selectedFiles) {
      const storageRef = ref(storage, `dealers_invited_bid_files/${file.name}`);
      await uploadBytes(storageRef, file);
      const fileURL = await getDownloadURL(storageRef);
      fileURLs.push(fileURL);
    }

    const combinedFileURLs = [...(formData.attachment || []), ...fileURLs];

    let updatedFormData: DealersInvitedTableDTO | undefined;
    if (formData.status === Status.INVITE_ACCEPTED) {
      updatedFormData = {
        ...formData,
        inviteAccepted: true,
        attachment: combinedFileURLs,
      };
    } else {
      updatedFormData = {
        ...formData,
        attachment: combinedFileURLs,
      };
    }

    onClose(updatedFormData, submitType || "");
  };

  return (
    <div className="modal-content">
      <form onSubmit={handleSubmit} ref={formRef}>
        <div className="form-fields">
          <div className="flex-column margin-topbottom-1">
            <label>{t("label-scrap-dealer")}</label>
            <input
              type="text"
              disabled
              name="dealerName"
              value={formData.dealerName}
              onChange={handleChange}
            />
          </div>
          <div className="flex-column margin-topbottom-1">
            <label>{t("label-proposed-value")} ({t(`unityENUM.${scrapData?.scrapInfo.unity}`)})</label>
            <CurrencyInput
              currency={countryConfigEntity?.currency}
              locale={countryConfigEntity?.locale}
              defaultValue={formData.proposedValue}
              onChangeValue={(event, originalValue, maskedValue) => {
                setFormData({
                  ...formData,
                  proposedValue: maskedValue.toString(),
                });
              }}
              InputElement={<input type="text" id="proposedValue" />}
            />
          </div>
          <div className="flex-column margin-topbottom-1">
            <label>{t("label-status")}</label>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <option></option>
              {Object.values(Status).map((status) => (
                <option key={status} value={status}>
                  {t(`dealer-invited-statusENUM.${status}`)}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-column margin-topbottom-1">
            <label>{t("label-attachment")}</label>
            <input
              style={{ width: "96.5%" }}
              type="file"
              name="attachment"
              multiple
              onChange={handleFileChange}
            />
            {Array.isArray(tableData?.attachment) &&
              tableData.attachment.length > 0 &&
              tableData?.attachment.map((url: string, index: number) => (
                <SparkTableBody style={{ marginTop: "0.5rem" }}>
                  <SparkTableRow
                    key={index}
                    onClick={() => window.open(url, "_blank")}
                  >
                    <SparkTableCell
                      style={{ color: "var(--bosch-accent-blue)" }}
                    >
                      {extractFileNameFromUrl(url)}
                    </SparkTableCell>
                    <SparkTableCell
                      style={{ justifyContent: "end" }}
                      className="icon-column flex-row"
                    >
                      <SparkIcon
                        pallete="tertiary"
                        className="icon-flag"
                        no-padding={true}
                        icName={"download"}
                      />
                      <p style={{ color: "transparent" }}>-----</p>
                    </SparkTableCell>
                  </SparkTableRow>
                </SparkTableBody>
              ))}
          </div>

          {selectedFiles.length > 0 && (
            <div className="flex-column margin-topbottom-1">
              {selectedFiles.map((file: File, index: number) => (
                <label key={index}>{file.name}</label>
              ))}
            </div>
          )}
        </div>
        <div className="flex-row buttons-section">
          <button
            className="primary"
            style={{ margin: "1rem 0rem" }}
            type="button"
            disabled={formData.inviteSended}
            onClick={() => {
              setSubmitType("sendInvite");
            }}
          >
            {formData.inviteSended
              ? t("label-invite-sended")
              : t("label-send-invite")}
          </button>
          <button
            className="neutral-white"
            onClick={() => onClose()}
            type="button"
            style={{ margin: "1rem 0rem" }}
          >
            {t("label-back")}
          </button>
          <button
            style={{ margin: "1rem 0rem" }}
            className="blue"
            type="submit"
            onClick={() => setSubmitType("save")}
          >
            {t("label-save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ScrapDealersInvitedModalForm;
