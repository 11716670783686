import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import {
  SparkIcon,
  SparkTable,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import { useAuth } from "../../../../contexts/auth-context";
import { useAlert } from "../../../../contexts/alert-context";
import { DriversDTO } from "../../drivers-DTO";
import { ScrapDealerDTO } from "../../../scrap-dealers/scrap-dealer-DTO";
import { ScrapGeneratorDTO } from "../../../scrap-generators/scrap-generators-DTO";
import { ExpirationDTO } from "../../../../components/Expiration/models/ExpirationDTO";
import { getScrapDealerById } from "../../../scrap-dealers/scrap-dealers-service";
import { getScrapGeneratorById } from "../../../scrap-generators/scrap-generator-service";
import {
  addNewDriver,
  getDriverAuthorizationById,
  getDriverById,
} from "../../drivers-service";
import DriverModalDriverAuthorization from "../driver-modal-driver-autorization/driver-modal-driver-autorization";
import Expiration from "../../../../components/Expiration/Expiration";
import { saveNewWithId } from "../../../../services/persistence";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import {
  formatDateToStringYearFirst,
  transformDateFormat,
} from "../../../../services/date-format";

interface ModalProps {
  onClose: () => void;
  driverId: string;
  dealerId: string;
}

const DriverForm: React.FC<ModalProps> = ({ onClose, driverId, dealerId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const originId = userDoc?.originId;
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [driver, setDriver] = useState<DriversDTO | null>(null);
  const phoneRef = useRef(null);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const [dealer, setDealer] = useState<ScrapDealerDTO>();
  const [authorizationInfo, setAuthorizationInfo] = useState<{
    [id: string]: { authorizationDate: string; expirationDate: string };
  }>({});
  const [selectedGenerator, setSelectedGenerator] =
    useState<ScrapGeneratorDTO>();
  const [isDriverAuthorizationModalOpen, setIsDriverAuthorizationModalOpen] =
    useState(false);
  const [scrapGenerators, setScrapGenerators] = useState<ScrapGeneratorDTO[]>(
    []
  );

  const [authorizationDate, setAuthorizationDate] = useState<string>("");
  const [expiration, setExpiration] = useState<ExpirationDTO>({
    originId: "",
    docName: "Driver License",
    expirationDate: "",
    warningPeriod: 30,
    solved: false,
    hasExpirationDate: false,
    origin: "",
  });

  const [statusColors, setStatusColors] = useState<{ [id: string]: string }>(
    {}
  );

  useEffect(() => {
    async function fetchStatusColors() {
      const colorsTemp: { [id: string]: string } = {};

      for (const item of scrapGenerators) {
        const color = await getDriverStatus(item);
        colorsTemp[item.id] = color;
      }
      setStatusColors(colorsTemp);
    }

    fetchStatusColors();
  }, [scrapGenerators]);

  const handleDataChange = (key: string, value: any) => {
    setExpiration((prevData: ExpirationDTO) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<DriversDTO>();

  useEffect(() => {
    fetchDriverData();
  }, [id, setValue]);

  function fetchDriverData() {
    const generatorId = profile == "scrap-generator" ? originId : undefined;

    if (generatorId) {
      getDriverAuthorizationById(`${driverId}${generatorId}`).then(
        (driverAutorization) => {
          if (driverAutorization) {
            setAuthorizationDate(
              formatDateToStringYearFirst(driverAutorization?.autorizationDate)
            );
            setExpiration(driverAutorization.expirationDocument);
          }
        }
      );
    }

    getScrapDealerById(dealerId).then(async (dealerData) => {
      if (dealerData) {
        setDealer(dealerData);
        const customerDataPromises = dealerData.scrapDealerInfo.customers.map(
          (customerId) => getScrapGeneratorById(customerId)
        );

        const customerData = await Promise.all(customerDataPromises);
        setScrapGenerators(
          customerData.filter(
            (customer): customer is ScrapGeneratorDTO => customer !== null
          )
        );
      }
    });

    if (driverId) {
      getDriverById(driverId, generatorId).then((data) => {
        if (data) {
          setDriver(data);

          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof DriversDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof DriversDTO, value as any);
            }
          });
        }
      });
    }
  }

  useEffect(() => {
    async function fetchStatusColorsAndDates() {
      const colorsTemp: { [id: string]: string } = {};
      const infoTemp: {
        [id: string]: { authorizationDate: string; expirationDate: string };
      } = {};

      for (const item of scrapGenerators) {
        const color = await getDriverStatus(item);
        colorsTemp[item.id] = color;

        const dateAuto = await getDriverAuthorizationById(
          `${driverId}${item.id}`
        );
        if (dateAuto) {
          infoTemp[item.id] = {
            authorizationDate: formatDateToStringYearFirst(
              dateAuto.autorizationDate
            ),
            expirationDate: dateAuto.expirationDocument.expirationDate,
          };
        }
      }

      setStatusColors(colorsTemp);
      setAuthorizationInfo(infoTemp);
    }

    fetchStatusColorsAndDates();
  }, [scrapGenerators]);

  async function getDriverStatus(item: ScrapGeneratorDTO) {
    const dateAuto = await getDriverAuthorizationById(`${driverId}${item.id}`);
    if (!dateAuto) return "#ed0007";

    const driverDate = new Date(dateAuto.expirationDocument.expirationDate);
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setMonth(currentDate.getMonth() + 1);

    if (driverDate > futureDate) {
      return "#00884a";
    } else if (driverDate >= currentDate && driverDate <= futureDate) {
      return "#ffcf00";
    } else {
      return "#ed0007";
    }
  }

  const onSubmit = async (driver: DriversDTO) => {
    try {
      if (id) {
        if (profile == "scrap-generator") {
          const dateAuthorization = new Date(authorizationDate);
          dateAuthorization.setDate(dateAuthorization.getDate() + 1);

          const dataToSave = {
            expirationDocument: expiration,
            autorizationDate: dateAuthorization,
          };
          const authorizationId = `${driverId}${originId}`;
          await saveNewWithId(
            COLLECTIONS.DRIVER_AUTORIZATION,
            {...dataToSave, tenantId: originId},
            authorizationId
          );
        }
        await addNewDriver(driver, id);
        setAlert({ text: t("alert-successfully-updated"), type: "success" });
        onClose();
      }
    } catch (e) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
    }
  };

  return (
    <div className="big-modal-content">
      {isDriverAuthorizationModalOpen && profile == "admin-bosch" && (
        <div className="modal-background">
          <DriverModalDriverAuthorization
            driverId={driver?.id || ""}
            generatorId={selectedGenerator?.id || ""}
            generatorName={
              selectedGenerator?.scrapGeneratorInfo.legalName || ""
            }
            driverName={driver?.driversInfoDTO.name || ""}
            onClose={() => {
              setIsDriverAuthorizationModalOpen(false);
              fetchDriverData();
            }}
          />
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields flex-column">
          <h2>{t("label-driver")}</h2>

          <div className="flex-column">
            <label htmlFor="legalName">{`${t("label-name")}*`}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="legalName"
              {...register("driversInfoDTO.name", { required: true })}
            />
            {errors.driversInfoDTO?.name && (
              <span>{t("error-input-required")}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="legalName">{`${t("label-rg")}*`}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="legalName"
              {...register("driversInfoDTO.rg", {
                required: true,
              })}
            />
            {errors.driversInfoDTO?.rg && (
              <span>{t("error-input-required")}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="legalName">{`${t("label-cnhValidate")}*`}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="date"
              id="legalName"
              {...register("driversInfoDTO.cnhValidate", {
                required: true,
              })}
            />
            {errors.driversInfoDTO?.cnhValidate && (
              <span>{t("error-input-required")}</span>
            )}
          </div>

        </div>

        {profile == "admin-bosch" ? (
          <div className="margin-topbottom-1">
            <SparkTable>
              <SparkTableHead>
                <SparkTableRow>
                  <SparkTableCell style={{ width: "2rem" }}></SparkTableCell>
                  <SparkTableCell>
                    {t("label-supported-generators")}
                  </SparkTableCell>
                  <SparkTableCell>{t("label-autorizationDate")}</SparkTableCell>
                </SparkTableRow>
              </SparkTableHead>

              {scrapGenerators.map((item) => (
                <SparkTableRow
                  onClick={() => {
                    setIsDriverAuthorizationModalOpen(true);
                    setSelectedGenerator(item);
                  }}
                >
                  <SparkTableCell style={{ width: "2rem" }}>
                    <div
                      style={{
                        width: "24px",
                        height: "24px",
                        backgroundColor: statusColors[item.id] || "#ed0007",
                      }}
                    ></div>
                  </SparkTableCell>
                  <SparkTableCell>
                    {item.scrapGeneratorInfo.legalName}
                  </SparkTableCell>
                  <SparkTableCell>
                    {transformDateFormat(
                      authorizationInfo[item.id]?.authorizationDate
                    ) || "-"}
                    {" - "}
                    {transformDateFormat(
                      authorizationInfo[item.id]?.expirationDate
                    ) || "-"}
                  </SparkTableCell>
                </SparkTableRow>
              ))}
            </SparkTable>
          </div>
        ) : (
          <div className="flex-column form-fields margin-topbottom-1">
            <div className="flex-column" style={{ gap: 10 }}>
              <label htmlFor="authorizationDate">{`${t(
                "label-autorizationDate"
              )}*`}</label>
              <input
                type="date"
                id="authorizationDate"
                value={authorizationDate}
                onChange={(e) => setAuthorizationDate(e.target.value)}
                placeholder={t("placeholder.generalEmpty")}
              />
            </div>
            <Expiration
              dto={expiration}
              dataHandler={handleDataChange}
              expirationDate={"expirationDate"}
              warningPeriod={"warningPeriod"}
              hasExpirationDate={"hasExpirationDate"}
              hasExpirationDateInit={expiration.hasExpirationDate}
              expirationDateInit={expiration.expirationDate}
              warningPeriodInit={expiration.warningPeriod}
            />
          </div>
        )}

        <div className="form-fields flex-column" style={{ marginTop: 20 }}>
          <div className="flex-column">
            <label htmlFor="observations">
              {t("label-general-observations")}
            </label>
            <textarea
              placeholder={t("placeholder.observations")}
              id="observations"
              {...register("observations.observations")}
            />
          </div>
        </div>

        <div className="flex-row buttons-section">
          <button className="neutral-white" onClick={onClose} type="button">
            {t("label-back")}
          </button>
          <button className="blue" type="submit">
            {t("label-save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default DriverForm;
