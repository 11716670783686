import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrapGeneratorDTO } from "../../../../scrap-generators-DTO";
import {
  getScrapGeneratorById,
  getSuccessFee,
} from "../../../../scrap-generator-service";
import { useAuth } from "../../../../../../contexts/auth-context";
import { BankDTO } from "../../../../../../components/BankScrapAutoComplete/bankDTO";
import { getUsersById } from "../../../../../settings/components/users/users-service";
import { getAllApprovers } from "../scrap-generator-approve-roll/scrap-generator-approve-roll-service";
import { getSigorLoginByOriginId } from "../../../../../generator-settings/components/sigor/sigor-service";
import { getScrapMaterialsByOriginId } from "../../../../../scraps/scraps-service";
import { ChecklistItem } from "../../../../../../components/ChecklistComponent/models/checklist-item-DTO";
import ChecklistGenericComponent from "../../../../../../components/ChecklistComponent/ChecklistComponent";

const ScrapGeneratorDetailsRegister: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [scrapGenerator, setScrapGenerator] =
    useState<ScrapGeneratorDTO | null>(null);
  const [selectedbanks, setSelectedBanks] = useState<BankDTO>();
  const [checklistItems, setChecklistItems] = useState<ChecklistItem[]>([]);
  const [allCompleted, setAllCompleted] = useState<boolean>(true);

  const fetchChecklistItems = async () => {
    if (id) {
      const checklistData: ChecklistItem[] = [];

      const addChecklistItem = (item: ChecklistItem) => {
        const exists = checklistData.some((i) => i.text === item.text);
        if (!exists) {
          checklistData.push(item);
        }
      };

      await getSuccessFee(id).then((fee) => {
        addChecklistItem({
          text: t("label-fee-complete-request"),
          link: `/scrap-generators-details/${id}/fee`,
          checked: !!fee.value,
        });
      });

      await getUsersById(id).then((users) => {
        addChecklistItem({
          text: t("label-register-users"),
          link: `/scrap-generators-details/${id}/users`,
          checked: users.length > 0,
        });
      });

      await getAllApprovers(id).then((approvers) => {
        addChecklistItem({
          text: t("label-configure-approval-instances"),
          link: `/scrap-generators-details/${id}/approve-roll`,
          checked: approvers.length > 0,
        });
      });

      await getSigorLoginByOriginId(id).then((sigorLogin) => {
        addChecklistItem({
          text: t("label-login-sigor-complete-alert"),
          link: `/scrap-generators-details/${id}/sigor`,
          checked: !!sigorLogin,
        });
      });

      await getScrapMaterialsByOriginId(id).then((scraps) => {
        addChecklistItem({
          text: t("label-add-residue"),
          link: `/scrap-generators-details/${id}/scraps`,
          checked: scraps.length > 0,
        });
      });

      setChecklistItems(checklistData);
      setAllCompleted(checklistData.every((item) => item.checked));
    }
  };

  const fetchScrapGenerator = async () => {
    if (!id) return;
    try {
      const data = await getScrapGeneratorById(id);
      if (data) {
        setScrapGenerator(data);
      } else {
        console.error("Data is undefined");
      }
    } catch (error) {
      console.error("Error fetching scrap Generator:", error);
    }
  };

  useEffect(() => {
    setChecklistItems([]);
    fetchChecklistItems();
    fetchScrapGenerator();
  }, [id]);

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="register-tab">
        <ChecklistGenericComponent
          title={t("label-complete-register-to-operate")}
          items={checklistItems}
        ></ChecklistGenericComponent>

        <div
          className="flex-row edit-section"
          style={{ justifyContent: "space-between" }}
        >
          <p
            style={{
              color: "var(--bosch-accent-turquoise)",
              fontWeight: "semibold",
            }}
          >
            {allCompleted ? t("label-completed-register") : " "}
          </p>
          <a
            onClick={() =>
              navigate(`/scrap-generators-edit/${scrapGenerator?.id}`)
            }
          >
            {t("label-edit")}
          </a>
        </div>

        {scrapGenerator?.scrapGeneratorInfo.headquarterId && (
          <div className="info-section">
            <div className="flex-row title">
              <h3>{t("label-headquarter")}</h3>
              <a
                onClick={() =>
                  navigate(
                    `/scrap-generators-details/${scrapGenerator?.scrapGeneratorInfo.headquarterId}/register`
                  )
                }
              >
                {t("label-view")}
              </a>
            </div>
            <div className="flex-column">
              <label>{t("label-headquarter-name")}</label>
              <input
                value={
                  scrapGenerator?.scrapGeneratorInfo.headquarterName || "-"
                }
                disabled={true}
              />
            </div>
          </div>
        )}

        <div className="info-section">
          <h3>{t("label-business-information")}</h3>
          <div className="flex-column">
            <label>{t("label-legal-name")}</label>
            <input
              value={scrapGenerator?.scrapGeneratorInfo.legalName || "-"}
              disabled={true}
            />
          </div>
          <div className="flex-column">
            <label>{t("label-trading-name")}</label>
            <input
              value={scrapGenerator?.scrapGeneratorInfo.tradingName || "-"}
              disabled={true}
            />
          </div>
          <div className="flex-column">
            <label>
              {scrapGenerator?.scrapGeneratorInfo.nationalRegisterLabel ||
                t("label-national-register")}
            </label>
            <input
              value={scrapGenerator?.scrapGeneratorInfo.nationalRegister || "-"}
              disabled={true}
            />
          </div>

          <div className="flex-column">
            <label>{t("label-segment")}</label>
            <input
              value={scrapGenerator?.scrapGeneratorInfo.segment || "-"}
              disabled={true}
            />
          </div>

          {scrapGenerator?.addressInfo?.countryName === "Brasil" && (
            <div className="flex-column">
              <label>{t("label-business-mtr-code")}</label>
              <input
                value={scrapGenerator?.scrapGeneratorInfo.mtrCode || "-"}
                disabled={true}
              />
            </div>
          )}
        </div>

        <div className="info-section">
          <h3>{t("label-location")}</h3>
          <div className="flex-column">
            <label>
              {scrapGenerator?.addressInfo.postalCodeLabel ||
                t("label-postal-code")}
            </label>
            <input
              value={scrapGenerator?.addressInfo.postalCode || "-"}
              disabled={true}
            />
          </div>

          <div className="flex-column">
            <label>{t("label-address")}</label>
            <input
              value={scrapGenerator?.addressInfo.address || "-"}
              disabled={true}
            />
          </div>

          <div className="double-fields">
            <div className="flex-column">
              <label>{t("label-city")}</label>
              <input
                value={scrapGenerator?.addressInfo.city || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-state")}</label>
              <input
                value={scrapGenerator?.addressInfo.state || "-"}
                disabled={true}
              />
            </div>
          </div>
        </div>

        <div className="info-section">
          <h3>{t("label-contact")}</h3>
          <div className="flex-column">
            <label>{t("label-contact-name")}</label>
            <input
              value={scrapGenerator?.contactInfo.name || "-"}
              disabled={true}
            />
          </div>
          <div className="double-fields">
            <div className="flex-column">
              <label>{t("label-email")}</label>
              <input
                value={scrapGenerator?.contactInfo.email || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-phone")}</label>
              <input
                value={scrapGenerator?.contactInfo.phone || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-function")}</label>
              <input
                value={scrapGenerator?.contactInfo.function || "-"}
                disabled={true}
              />
            </div>
          </div>
        </div>

        <div className="info-section">
          <h3>{t("label-bank-details")}</h3>
          <div className="double-fields">
            <div className="flex-column">
              <label>{t("label-bank")}</label>
              <input
                value={scrapGenerator?.bankAccountInfo?.bank.name || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-account-type")}</label>
              <input
                value={
                  t(
                    `accountTypesENUM.${scrapGenerator?.bankAccountInfo.accountType}_ACCOUNT`
                  ) || "-"
                }
                disabled={true}
              />
            </div>
          </div>
          <div className="triple-fields">
            <div className="flex-column">
              <label>{t("label-account-number")}</label>
              <input
                value={scrapGenerator?.bankAccountInfo.accountNumber || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-digit")}</label>
              <input
                value={scrapGenerator?.bankAccountInfo.digit || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-agency")}</label>
              <input
                value={scrapGenerator?.bankAccountInfo.agency || "-"}
                disabled={true}
              />
            </div>
          </div>
        </div>

        <div className="info-section">
          <h3>{t("label-general-observations")}</h3>
          <div>
            <textarea
              disabled
              value={scrapGenerator?.observations.observations || ""}
            ></textarea>
          </div>
        </div>

        <div className="flex-row buttons-section">
          <button
            onClick={() => {
              navigate("/scrap-generators");
            }}
            className="neutral-white"
          >
            {t("label-back")}
          </button>
        </div>
        <br />
        <br />
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapGeneratorDetailsRegister;
