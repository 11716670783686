import React, { useState } from "react";
import ModalWelcomeFirstAccess from "../modal-welcome-first-access/modal-welcome-first-access";
import { useTranslation } from "react-i18next";

const ButtonFirstAccess = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const { t } = useTranslation();

  return (
    <div>
      <button
        className="login-without-password"
        type="button"
        onClick={handleOpenModal}
      >
        {t("label-first-access")}
      </button>
      {isModalOpen && <ModalWelcomeFirstAccess onClose={handleCloseModal} />}
    </div>
  );
};

export default ButtonFirstAccess;
