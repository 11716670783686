import React, { useState } from "react";
import { resetPasswordByEmail } from "../../../../services/auth";
import { t } from "i18next";
import { useAlert } from "../../../../contexts/alert-context";

interface ModalProps {
  onClose: () => void;
}

const ModalWelcomeFirstAccess: React.FC<ModalProps> = ({ onClose }) => {
  const { setAlert } = useAlert();
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    if (email === "") {
      setAlert({ text: `${t("error-input-required")}`, type: "error" });
    } else {
      resetPasswordByEmail(email);
      setAlert({ text: `${t("alert-info-email-sended")}`, type: "info" });
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content-first" onClick={(e) => e.stopPropagation()}>

        <p className="first-access-title">{t("label-welcome-firstAccess")}</p>

        

        <p className="info-text">{t("label-welcome-information")}</p>
     
        <input
          value={email}
          type="email"
          id="email"
          placeholder={t("input-email")}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br/>
        <b><p className="info-text" style={{fontSize:"15px"}}>{t("label-welcome-info")}</p></b>
        <br/>
        <button
          className="primary"
          type="button"
          onClick={() => handleSubmit()}
        >
          {t("label-send")}
        </button>
      </div>
    </div>
  );
};

export default ModalWelcomeFirstAccess;
