import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../utils/firebase-config";
import ImportExportCsv from "../../../components/ImportExportCsv/ImportExportCsv";
import NoData from "../../../components/NoData/NoData";
import { useAuth } from "../../../contexts/auth-context";
import FilterComponent from "../../../components/FIlter/filter";
import { receiveNumberFormatWithMilhar } from "../../../services/number-format";
import { COLLECTIONS } from "../../../assets/enums/firebase-colections";
import { formatTimestampDate } from "../../../services/date-format";
import Tooltip from '../../../components/tooltip/tooltip';
const FinancialToReceiveView: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const originId = userDoc?.originId;
  const { id } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<any>([]);
  const tableHeader = [
    `${t("label-material")}`,
    `${t("label-scrap-generator")}`,
    `${t("label-scrap-dealer")}`,
    `${t("label-quantity")}`,
    `${t("label-value")}`,
    `${t("label-date")}`,
  ];
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const handleFilterClick = (filteredData: any[]) => {
    setFilteredData(filteredData);
  };

  const sortByname = (data: any[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.scrapCategory.name?.toString().toLowerCase() || "";
      const nameB = b.scrapCategory.name?.toString().toLowerCase() || "";
      return nameA.localeCompare(nameB);
    });
  };

  const sortBycity = (data: any[]) => {
    return [...data].sort((a, b) => {
      const cityA = a.operationAddress.city?.toString().toLowerCase() || "";
      const cityB = b.operationAddress.city?.toString().toLowerCase() || "";
      return cityA.localeCompare(cityB);
    });
  };
  const sortByorigin = (data: any[]) => {
    return [...data].sort((a, b) => {
      const originA = a.originName?.toString().toLowerCase() || "";
      const originB = b.originName?.toString().toLowerCase() || "";
      return originA.localeCompare(originB);
    });
  };

  const sortOptions = [
    { label: t("label-material"), sortFunction: sortByname },
    { label: t("label-city"), sortFunction: sortBycity },
    { label: t("label-scrap-generator"), sortFunction: sortByorigin },
  ];

  const handleSortClick = (sortFunction: (data: any[]) => any[]) => {
    const sortedData = sortFunction([...filteredData]);
    setFilteredData(sortedData);
  };

  const fetchTableData = async () => {
    let q ;
    if (profile == "admin-bosch"){
        q = query(
        collection(firestore, COLLECTIONS.FINANCIAL),
        where("status", "==", "RECEIVED")
      );
    }
    else{
        q = query(
        collection(firestore, COLLECTIONS.FINANCIAL),
        where("status", "==", "RECEIVED"),
        where("generatorId", "==", originId)
      );
    }

    const querySnapshot = await getDocs(q);

    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      searchData: doc.data()["scrapDealerName"].concat(
        " ",
        doc.data()["scrapGeneratorName"],
        " "
        // doc.data()["specialDescription"],
        // " ",
        // doc.data()["originName"],
        // " ",
        // doc.data()["operationAddress"]["city"],
        // " ",
        // doc.data()["operationAddress"]["state"]
      ),
    })) as unknown as any[];
    setTableData(items);
    setFilteredData(items);
  };

  useEffect(() => {
    fetchTableData();
  }, [id]);

  const handleCloseModal = () => {
    fetchTableData();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div style={{ marginBottom: "120px" }}>
        <div className="flex-row" style={{ justifyContent: "space-between" }}>
          <div className="flex-row">
          {profile === "admin-bosch" && (
            <ImportExportCsv
              exportApi="exportscrapbygeneratorscsv"
              importApi=""
              onClose={handleCloseModal}
            />
          )}
          </div>
          <FilterComponent
            data={tableData}
            onFilterClick={handleFilterClick}
            sortOptions={sortOptions}
          />
        </div>

        <br />
        <br />
        {tableData.length === 0 && <NoData />}

        {tableData.length > 0 && (
          <>
            <div className="info-section">
              <SparkTable>
                <SparkTableHead>
                  <SparkTableRow>
                    {tableHeader.map((header, index) => (
                      <SparkTableCell key={index}>
                        <strong>{header.toUpperCase()}</strong>
                      </SparkTableCell>
                    ))}
                  </SparkTableRow>
                </SparkTableHead>

                <SparkTableBody>
                  {filteredData.map((rowItem: any, rowIndex: any) => (
                    <SparkTableRow
                      key={rowIndex}
                    >
                         <SparkTableCell>
                        <Tooltip
                          text={
                            rowItem.scrapMaterial.scrapCategory.isHazardousMaterial
                              ? t("label-hazardous-material")
                              : t("label-non-hazardous-material")
                          }
                        >
                          <span>{rowItem.scrapMaterialName}</span>
                        </Tooltip>
                      </SparkTableCell>
                      <SparkTableCell>
                        {rowItem.scrapGeneratorName}
                      </SparkTableCell>
                      <SparkTableCell>{rowItem.scrapDealerName}</SparkTableCell>
                      <SparkTableCell>
                        {receiveNumberFormatWithMilhar(rowItem.quantity)}
                      </SparkTableCell>
                      <SparkTableCell>
                      {rowItem.currency}  {receiveNumberFormatWithMilhar(rowItem.totalValue)}
                      </SparkTableCell>
                      <SparkTableCell>
                        {formatTimestampDate(rowItem.dateToCollect)}
                      </SparkTableCell>
                    </SparkTableRow>
                  ))}
                </SparkTableBody>
              </SparkTable>
            </div>
          </>
        )}
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default FinancialToReceiveView;
