import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";

import {

  CountryConfigDTO,
  CountryDTO,
  ObservationsDTO,
} from "./country-config-DTO";
import { saveFiles, saveNewAsync, saveNewWithId, update } from "../../../../services/persistence";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { firestore } from "../../../../utils/firebase-config";


// add new
export async function addNew(
data: CountryDTO, id: string | undefined, acceptanceTermsFile: File[]) {
  data.CountryConfigDTO.country = data.CountryConfigDTO.countries.replace(", ", ",").split(",");
  if (data.id == undefined) {
    saveNewAsync(COLLECTIONS.COUNTRY_CONFIG, data.CountryConfigDTO).then((docRef) => {
      saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
      if (acceptanceTermsFile.length > 0) {
        saveFiles(
          COLLECTIONS.COUNTRY_CONFIG,
          docRef.id,
          COLLECTIONS.COUNTRY_CONFIG,
          acceptanceTermsFile,
          "acceptanceTermsFile"
        );
      }
    });
  } else {
    update(COLLECTIONS.COUNTRY_CONFIG, data.CountryConfigDTO, data.id);
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
    if (acceptanceTermsFile.length > 0) {
      saveFiles(
        COLLECTIONS.COUNTRY_CONFIG,
        data.id,
        COLLECTIONS.COUNTRY_CONFIG,
        acceptanceTermsFile,
        "acceptanceTermsFile"
      );
    }
  }
}

export async function getCountryConfigs(): Promise<CountryConfigDTO[]> {
  const collectionRef = collection(firestore, COLLECTIONS.COUNTRY_CONFIG);

  const q = query(collectionRef, orderBy("country"));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as CountryConfigDTO[];
  return items;
}

export const getById = async (
  id: string
): Promise<CountryDTO | null> => {
  const CountryConfigDTO = await getInfoById(id);
  const observations = await getObservation(id);

  return {
    id,
    CountryConfigDTO,
    observations,
  };
};

export async function getInfoById(id: string): Promise<CountryConfigDTO> {
  const docRef = doc(firestore, COLLECTIONS.COUNTRY_CONFIG, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as CountryConfigDTO;
}

export async function getObservation(id: string): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}
