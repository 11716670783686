import { doc, getDoc } from "firebase/firestore";
import { getEmailsWarningsById } from "../pages/settings/components/Emails-warning/emails-warning-service";
import { callFunction } from "./persistence";
import { firestore } from "../utils/firebase-config";
import { COLLECTIONS } from "../assets/enums/firebase-colections";
import scrapAdmin from "../pages/scraps/scrap-admin-generator-view/scrap-admin";
import { ContactInfoDTO } from "../pages/contacts/contacts-DTO";
import { generateEmailTemplate } from "../assets/templates/email-template";



export async function sendEmailSDM(subject: string, content: string) {
  const contentWithTemplate = generateEmailTemplate(content)
  await getListWarningMail().then((list) => {
    
    callFunction("sendemail", {
      to: list,
      subject: subject,
      content: contentWithTemplate,
    });
  });
  
}



export async function getListWarningMail(): Promise<string[]> {
  const emailsWarningInfoList = await getEmailsWarningsById("bosch");
  if (emailsWarningInfoList) {
    return emailsWarningInfoList.map((info) => info.email);
  } else {
    return [];
  }
}

export async function sendEmailGeneratorOrDealer(subject: string, content: string, receiverId: string) {
  const docRefContact = doc(
    firestore,
    COLLECTIONS.CONTACTS,
    receiverId + "contact"
  );
  const docSnap = await getDoc(docRefContact);
  const contact = docSnap.data() as ContactInfoDTO;

    callFunction("sendemail", {
      to: contact.email,
      subject: subject,
      content: content,
    });
}
