import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { useAlert } from "../../../../../contexts/alert-context";
import { addNew, getById } from "../country-config-service";
import { useAuth } from "../../../../../contexts/auth-context";
import { CountryDTO } from "../country-config-DTO";
import { SparkIcon, SparkTableBody, SparkTableCell, SparkTableRow } from "@bosch-web-dds/spark-ui-react";
import { extractSubstringFile } from "../../../../../services/string-treatment";


const CountryConfigForm: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const { id, countryId } = useParams<{ id: string, countryId: string }>();
  const { setAlert } = useAlert();
  const [country, setCountry] = useState<string | null>(null);
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<CountryDTO>();


  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const [document, setDocument] = useState<CountryDTO | null>(null);


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []) as File[];
    setSelectedFiles(files);

    const previewsArray = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise<string>((resolve) => {
        reader.onloadend = () => {
          if (typeof reader.result === "string") {
            resolve(reader.result);
          }
        };
      });
    });

    Promise.all(previewsArray).then((previews) => {
      setPreviews(previews);
    });
  };

 

  useEffect(() => {
    if (countryId) {
      getById(countryId).then((data) => {
        if (data) {
          setDocument(data);
          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof CountryDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof CountryDTO, value as any);
            }
          });

          setValue(
            "CountryConfigDTO.countries",
            data.CountryConfigDTO.countries
          );
          setCountry(data.CountryConfigDTO.countries);
        }
      });
    }
  }, [id, setValue, country]);



  const onSubmit = async (document: CountryDTO) => {
    console.log(document)

    if (selectedFiles.length == 0 && !document.CountryConfigDTO.acceptanceTermsFile) {
      setAlert({
        text: t("label-no-filePDF"),
        type: "error",
      });
    }
    else{
      try {
        await addNew(document,id,selectedFiles);
        setAlert({ text: t("alert-successfully-registered"), type: "success" });
        navigate(`/settings/${id}/country-config`);
  
      } catch (e) {
        console.error("Erro ao cadastrar: ", e);
        setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
      }
    }

  };


  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-fields flex-column">
            <h2>{t("label-country")}</h2>

            <div className="flex-column">
              <label htmlFor="material-container-type">
                {`${t("label-country")}*`}
              </label>
              <input
                placeholder={t("placeholder.generalEmpty")}
                type="text"
                id="material-container-type"
                {...register("CountryConfigDTO.countries", {
                  required: true,
                })}
              />
              {errors.CountryConfigDTO?.countries && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor="label-currency-name">
                {`${t("label-currency-name")}*`}
              </label>
              <input
                placeholder={t("placeholder.generalEmpty")}
                type="text"
                id="label-currency-name"
                {...register("CountryConfigDTO.currencyName", {
                  required: true,
                })}
              />
              {errors.CountryConfigDTO?.currencyName && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor="label-currency-symbol">
                {`${t("label-currency-symbol")}*`}
              </label>
              <input
                placeholder={t("placeholder.generalEmpty")}
                type="text"
                id="label-currency-symbol"
                {...register("CountryConfigDTO.currencyLabel", {
                  required: true,
                })}
              />
              {errors.CountryConfigDTO?.currencyLabel && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor="label-currency">
                {`${t("label-currency")}*`}
              </label>
              <input
                placeholder={t("placeholder.generalEmpty")}
                type="text"
                id="label-currency"
                {...register("CountryConfigDTO.currency", {
                  required: true,
                })}
              />
              {errors.CountryConfigDTO?.currency && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor="label-currency-formatting">
                {`${t("label-currency-formatting")}*`}
              </label>
              <input
                placeholder={t("placeholder.generalEmpty")}
                type="text"
                id="label-currency-formatting"
                {...register("CountryConfigDTO.currencyFormatting", {
                  required: true,
                })}
              />
              {errors.CountryConfigDTO?.currencyFormatting && (
                <span>{t("error-input-required")}</span>
              )}
            </div>
            <div className="flex-column">
              <label htmlFor="label-locale">
                {`${t("label-locale")}*`}
              </label>
              <input
                placeholder={t("placeholder.generalEmpty")}
                type="text"
                id="label-locale"
                {...register("CountryConfigDTO.locale", {
                  required: true,
                })}
              />
              {errors.CountryConfigDTO?.locale && (
                <span>{t("error-input-required")}</span>
              )}
            </div>
            <div className="flex-column">
              <label htmlFor="label-national-register">
                {`${t("label-national-register")}*`}
              </label>
              <input
                placeholder={t("placeholder.generalEmpty")}
                type="text"
                id="label-national-register"
                {...register("CountryConfigDTO.nationalRegister", {
                  required: true,
                })}
              />
              {errors.CountryConfigDTO?.nationalRegister && (
                <span>{t("error-input-required")}</span>
              )}
            </div>
            <div className="flex-column">
              <label htmlFor="label-national-register-formatting">
                {`${t("label-national-register-formatting")}*`}
              </label>
              <input
                placeholder={t("placeholder.generalEmpty")}
                type="text"
                id="label-national-register-formatting"
                {...register("CountryConfigDTO.nationalRegisterFormatting", {
                  required: true,
                })}
              />
              {errors.CountryConfigDTO?.nationalRegisterFormatting && (
                <span>{t("error-input-required")}</span>
              )}
            </div>
            <div className="flex-column">
              <label htmlFor="label-postal-code">
                {`${t("label-postal-code")}*`}
              </label>
              <input
                placeholder={t("placeholder.generalEmpty")}
                type="text"
                id="label-postal-code"
                {...register("CountryConfigDTO.postalCode", {
                  required: true,
                })}
              />
              {errors.CountryConfigDTO?.postalCode && (
                <span>{t("error-input-required")}</span>
              )}
            </div>
            <div className="flex-column">
              <label htmlFor="label-postal-code-formatting">
                {`${t("label-postal-code-formatting")}*`}
              </label>
              <input
                placeholder={t("placeholder.generalEmpty")}
                type="text"
                id="label-postal-code-formatting"
                {...register("CountryConfigDTO.postalCodeFormatting", {
                  required: true,
                })}
              />
              {errors.CountryConfigDTO?.postalCodeFormatting && (
                <span>{t("error-input-required")}</span>
              )}
            </div>
            <div className="flex-column">
              <label htmlFor="label-payment-gateway">
                {`${t("label-payment-gateway")}*`}
              </label>
              <input
                placeholder={t("placeholder.generalEmpty")}
                type="text"
                id="label-payment-gateway"
                {...register("CountryConfigDTO.paymentGateway", {
                  required: true,
                })}
              />
              {errors.CountryConfigDTO?.paymentGateway && (
                <span>{t("error-input-required")}</span>
              )}
            </div>
          </div>
          <br/>
          <div className="flex-column">
          <label htmlFor="legalName" style={{marginBottom:"5px", fontSize:"14px",marginLeft:"6px"}}>{`${t("label-uploadTerms")}*`}</label>
          
           <div className="input-and-preview">
             <input
               type="file"
               id="imageUpload"
               accept="application/pdf"
               
               onChange={handleFileChange}
               
             />
           </div>
         </div>

       {document?.CountryConfigDTO.acceptanceTermsFile?.length &&
         document?.CountryConfigDTO.acceptanceTermsFile.length > 0 && (
           <div className="flex-column">
             <div className="custom-line-hr" />
             <h4>
               <strong>{t("label-documentsTerms")}</strong>
             </h4>
             <SparkTableBody>
               {document?.CountryConfigDTO?.acceptanceTermsFile.map(
                 (rowItem: any, rowIndex: any) => (
                   <SparkTableRow
                     key={rowIndex}
                     onClick={() => window.open(rowItem, "_blank")}
                   >
                     <SparkTableCell
                       style={{ color: "var(--bosch-accent-blue)" }}
                     >
                       {extractSubstringFile(rowItem)}
                     </SparkTableCell>
                     <SparkTableCell className="icon-column">
                       <SparkIcon
                         pallete="tertiary"
                         className="icon-flag"
                         no-padding={true}
                         icName={"upload"}
                         
                       />
                     </SparkTableCell>
                   </SparkTableRow>
                 )
               )}
             </SparkTableBody>
           </div>
         )}
          <br />
          <div className="form-fields flex-column">
            <div className="flex-column">
              <label htmlFor="observations" style={{ paddingBottom: 10 }}>
                {t("label-general-observations")}
              </label>
              <textarea
                placeholder={t("placeholder.observations")}
                id="observations"
                {...register("observations.observations")}
              />
            </div>
          </div>
          <div className="flex-row buttons-section">
            <button className="neutral-white" onClick={() => navigate("/settings/bosch/country-config")} type="button">
              {t("label-back")}
            </button>
            <button className="blue" type="submit">
              {t("label-save")}
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default CountryConfigForm;
