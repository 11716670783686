import { logEvent, setUserProperties } from "firebase/analytics";
import { analytics } from "../utils/firebase-config";

export function logEventAnalytics(event: string) {
  logEvent(analytics, event);
}

export function logScreenViewAnalytics(screenName: string) {
  logEvent(analytics, "screen_view", {
    firebase_screen: screenName,
    firebase_screen_class: screenName,
  });
}

export function logUserProperty(property: any) {
  setUserProperties(analytics, property);
}


export const Analytics = {
    LANDING_PAGE: "landing_page",
    LANDING_PAGE_CONSULTANT: "landing-page-email",
  };
  