import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../utils/firebase-config";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import {
  DriversInfoDTO,
  DriversDTO,
  ObservationsDTO,
  DriverAuthorizationDTO,
} from "./drivers-DTO";
import {
  saveFiles,
  saveNewAsync,
  saveNewWithId,
  update,
} from "../../services/persistence";
import { ScrapDealerInfoDTO } from "../scrap-dealers/scrap-dealer-DTO";

// add new
export async function addNewDriver(
  data: DriversDTO,
  dealerId: string,
) {
  if (data.id == undefined) {
    data.driversInfoDTO.originId = dealerId;
    saveNewAsync(COLLECTIONS.DRIVERS, data.driversInfoDTO).then((docRef) => {
      saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
    });
  } else {
    update(COLLECTIONS.DRIVERS, data.driversInfoDTO, data.id);
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
  }
}

async function getOriginName(id: string): Promise<string> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_DEALERS, id);
  const docSnap = await getDoc(docRef);

  const dealer = docSnap.data() as ScrapDealerInfoDTO;
  return dealer.tradingName;
}

export async function getDriversById(id: string): Promise<DriversInfoDTO[]> {
  const driversCollectionRef = collection(firestore, COLLECTIONS.DRIVERS);

  const q = query(driversCollectionRef, where("originId", "==", id));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc.data()["name"].concat(
      " ",

      doc.data()["rg"],
      doc.data()["cnhValidate"],
      doc.data()["autorizationDate"]
    ),
  })) as unknown as DriversInfoDTO[];
  return items;
}

export const getDriverById = async (
  id: string,
  idGenerator?: string
): Promise<DriversDTO | null> => {
  const driversInfoDTO = await getDriver(id);
  const observations = await getVehicleObservation(id);
  const driverAuthorizationDTO = await getExpirationDocumentDriver(
    id,
    idGenerator
  );

  return {
    id,
    driversInfoDTO,
    observations,
    driverAuthorizationDTO,
  };
};

export const getDriverAuthorizationById = async (
  id: string
): Promise<DriverAuthorizationDTO | null> => {
  const docRef = doc(firestore, COLLECTIONS.DRIVER_AUTORIZATION, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as DriverAuthorizationDTO;
};

export async function getDriversByGeneratorAndDealer(
  dealerId: string,
  generatorId: string
): Promise<DriversDTO[]> {
  const driversCollectionRef = collection(firestore, COLLECTIONS.DRIVERS);

  const q = query(driversCollectionRef, where("originId", "==", dealerId ? dealerId : ""));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as DriversInfoDTO[];

  const itemsDriverPromises = items.map(async (driver) => {
    const driverTemp = await getDriverById(driver.id, generatorId);
    return driverTemp || null;
  });

  const itemsDriver = (await Promise.all(itemsDriverPromises)).filter(
    (driver) => driver !== null
  ) as DriversDTO[];

  return itemsDriver;
}

export async function getExpirationDocumentDriver(
  idDriver: string,
  idGenerator?: string
): Promise<DriverAuthorizationDTO> {
  const docRef = doc(
    firestore,
    COLLECTIONS.DRIVER_AUTORIZATION,
    idDriver + idGenerator
  );
  const docSnap = await getDoc(docRef);

  return docSnap.data() as DriverAuthorizationDTO;
}

export async function getDriver(id: string): Promise<DriversInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.DRIVERS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as DriversInfoDTO;
}

export async function getVehicleObservation(
  id: string
): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}
