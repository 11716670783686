import React, {useState, useEffect} from "react";
import "./Slider.css"

export interface SliderProps {
    options: { display: string, viewType: "image" | "text", value: string }[];
    setState: (value: string) => void;
}

const Slider: React.FC<SliderProps> = ({ options, setState }) => {
    const [actualValue, setActualValue] = useState<string>(options[0].value)

    useEffect(() => {
        setState(actualValue)
    }, [actualValue])

    return (
        <div className="slider-main">
            {
                options.map((obj) =>
                    <div 
                        key={obj.value} // Add a unique key prop here
                        className="centered-flex-box" 
                        style={{ backgroundColor: actualValue === obj.value ? "lightgray" : "transparent" }} 
                        onClick={() => setActualValue(obj.value)}
                    >
                        {obj.viewType === 'text' ? <div>{obj.display}</div> : <img width={24} height={24} src={obj.display} />}
                    </div>
                )
            }
        </div>
    )
}

export default Slider