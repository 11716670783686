import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { t } from "i18next";

import {
    SparkIcon,
    SparkTableBody,
    SparkTableCell,
    SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import { useParams } from "react-router-dom";

import { useAuth } from "../../../../contexts/auth-context";
import {
    getScrapAdminContract,
    getscrapInfo,
} from "../../../scraps/scrap-admin-service";
import { getScrapDealerById } from "../../../scrap-dealers/scrap-dealers-service";
import ScrapMaterialInputAutocomplete from "../../../../components/ScrapMaterialInputAutocomplete/ScrapMaterialInputAutocomplete";
import { useAlert } from "../../../../contexts/alert-context";
import {
    getAdrressByEntityId,
    getCountryConfigById,
} from "../../../../services/address-service";
import { extractFileNameFromUrl } from "../../../../services/string-treatment";

import { addNewFsdr } from "./fsdr-service";
import { ExpirationDTO } from "../../../../components/Expiration/models/ExpirationDTO";
import Expiration from "../../../../components/Expiration/Expiration";
import { CADRItemDTO } from "../../models/cadri-item-DTO";
import { FSDRItemDTO } from "./fsdr-item-DTO";
import { CountryConfigDTO } from "../../../settings/components/Country-config/country-config-DTO";

interface NewCollectModalProps {
    originEntity: string;
    item?: CADRItemDTO;
    profile: string;
    onSave: (item: CADRItemDTO) => void;
    onClose: () => void;
}

const NewFSDRModal: React.FC<NewCollectModalProps> = ({
    item,
    onSave,
    onClose,
    profile,
    originEntity,
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<CADRItemDTO>({
        defaultValues: item ,
    });

    const { userDoc } = useAuth();
    const originId = userDoc?.originId;
    const { id } = useParams<{ id: string }>();

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [autocompleteError, setAutocompleteError] = useState(false);
    const watchedAttachmentfsdr = watch("attachmentFSDR");
    const [scrapMaterialPreview, setScrapMaterialPreview] = useState<any>();
    const [scrapMaterialContract, setScrapMaterialContract] = useState<any>();
    const [scrapDealerDetails, setScrapDealerDetails] = useState<any>();

    const [countryConfigEntity, setCountryConfigEntity] =
        useState<CountryConfigDTO>();

    const { setAlert } = useAlert();


    const [expiration, setExpiration] = useState<ExpirationDTO>({
        originId: "",
        docName: "FSDR",
        expirationDate: "",
        warningPeriod: 0,
        solved: false,
        hasExpirationDate: true,
        origin: "",
    });

    const handleDataChange = (key: string, value: any) => {
        setExpiration((prevData: ExpirationDTO) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const fetchEntityCountry = async () => {
        try {
            const entityId = id || originId;
            if (entityId) {
                const data = await getAdrressByEntityId(entityId);
                if (data?.country) {
                    const countryConfig = await getCountryConfigById(data.country);
                    setCountryConfigEntity(countryConfig);

                }
            }
        } catch (error) {

        }
    };

    useEffect(() => {
        fetchEntityCountry();

    }, []);

    useEffect(() => {
        if (item && item.fsdr) {
            setValue("fsdr.fsdrNumber", item?.fsdr.fsdrNumber || "");

            getscrapInfo(item.scrapMaterial).then((scrapMaterial) => {
                if (scrapMaterial) {
                    setValue("scrapMaterial", scrapMaterial);
                    setScrapMaterialPreview(scrapMaterial);
                    getScrapDealerById(scrapMaterial.contractDealer).then((dealer) => {
                        setScrapDealerDetails(dealer);

                    });
                }
            });

            getScrapAdminContract(item.scrapMaterial).then((scrapContract) => {
                if (scrapContract) {
                    setScrapMaterialContract(scrapContract);

                }
            });

            if (item.fsdr &&  item.fsdr.expirationDocument) {
                setExpiration({
                    originId: item.fsdr.expirationDocument.originId || "",
                    docName: item.fsdr.expirationDocument.docName || "FSDR",
                    expirationDate: item.fsdr.expirationDocument.expirationDate || "",
                    warningPeriod: item.fsdr.expirationDocument.warningPeriod || 60,
                    solved: item.fsdr.expirationDocument.solved || false,
                    hasExpirationDate: item.fsdr.expirationDocument.hasExpirationDate || false,
                    origin: item.fsdr.expirationDocument.origin || "",
                });

            }
        }
    }, [item, setScrapMaterialPreview, setValue]);

    const handleFilechange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files?.length > 0) {
            setSelectedFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);

        }
    };

    const handleSetScrapMaterial = async (scrapMaterial: any) => {
        setValue("scrapMaterial", scrapMaterial);
        setScrapMaterialPreview(scrapMaterial);
        setAutocompleteError(false);

        const contractDetails = await getScrapAdminContract(scrapMaterial.id);
        setScrapMaterialContract(contractDetails);

        const scrapDealerDetails = await getScrapDealerById(
            scrapMaterial.contractDealer
        );
        setScrapDealerDetails(scrapDealerDetails);


    };

    const onSubmit = async (data: CADRItemDTO) => {
        if (!data.scrapMaterial) {
            setAutocompleteError(true);
        } else {
         
        
           
              data.scrapMaterial = data.scrapMaterial.id;
            if (countryConfigEntity) {
                data.locale = countryConfigEntity.locale;
            }
            
            data.fsdr.expirationDocument = expiration;
            await addNewFsdr(data, selectedFiles)
                .then(() => {
                    setAlert({
                        text: t("alert-successfully-registered"),
                        type: "success",
                    });
                    onClose();
                    onSave(data.scrapMaterial);
                })
                .catch((error) => {
                    console.error("Failed to add item to FSDR:", error);
                    setAlert({
                        text: t("alert-error-occurred"),
                        type: "error",
                    });
                });
        }
    };

    return (
        <div
            className="modal-content"
            style={{ gap: "20px", padding: "2rem 1rem" }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3>{t("FSDR")}</h3>
                <div className="form-fields">
                    <div className="flex-column">
                        <ScrapMaterialInputAutocomplete
                            originEntity={originEntity}
                            initialValue={item?.scrapMaterial}
                            isDisabled={item ? true : false}
                            label={t("label-material")}
                            onChange={(scrapMaterial) =>
                                handleSetScrapMaterial(scrapMaterial)
                            }
                            placeholder={t("label-material")}
                        />
                        <div className="flex-row justify-between">
                            {autocompleteError && <span>{t("error-input-required")}</span>}
                            <div></div>
                            <p
                                style={{
                                    color: "var(--extra-grey-medium)",
                                    fontSize: 12,
                                }}
                            >
                                {t("label-material-contracted")}
                            </p>
                        </div>
                    </div>

                    {scrapMaterialPreview && (
                        <div
                            className="resume-material-collect flex-row margin-topbottom-1"
                            style={{ gap: 10 }}
                        >
                            <div className="flex-column">
                                <img
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                        borderRadius: 5,
                                    }}
                                    src={
                                        scrapMaterialPreview?.images &&
                                            scrapMaterialPreview?.images?.length > 0
                                            ? scrapMaterialPreview.images[0]
                                            : scrapMaterialPreview.scrapCategory.image
                                    }
                                />
                            </div>
                            <div
                                className="flex-column justify-between"
                                style={{ width: "100%" }}
                            >
                                <div className="flex-column" style={{ gap: 5 }}>
                                    <div className="justify-between flex-row">
                                        <h3 style={{ margin: 0, marginBottom: 8 }}>
                                            {scrapMaterialPreview.scrapCategory.name}
                                        </h3>
                                        <a
                                            href={profile === "admin-bosch"
                                                ? `/scrap-generators-details/${scrapMaterialPreview.originId}/scraps-form/${scrapMaterialPreview.id}`
                                                : `/generator-scraps/${scrapMaterialPreview.originId}/generator-scraps-form/${scrapMaterialPreview.id}`}
                                            className="a-link-style"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {t("label-details")}
                                        </a>
                                    </div>
                                </div>
                                <div className="flex-column" style={{ gap: 2 }}>
                                    <h5 style={{ margin: 0 }}>{t("material-contract-date")}:</h5>
                                    <p>{scrapMaterialContract?.contractDate || "xx-xx-xxxx"}</p>
                                </div>
                                <div className="flex-row justify-between align-end">
                                    <div className="flex-column" style={{ gap: 2 }}>
                                        <h5 style={{ margin: 0 }}>
                                            {t("label-responsible-shipping")}:
                                        </h5>
                                        <p>
                                            {t(`label-${scrapMaterialPreview.responsibleShipping}`)}
                                        </p>
                                    </div>
                                    <div className="flex-column align-end">
                                        <div className="flex-row" style={{ gap: 5 }}>
                                            <h3 style={{ margin: 0 }}>
                                                {scrapMaterialContract?.contractValue || "xx"}
                                            </h3>
                                            <p>{`/${t(
                                                `unityENUM.${scrapMaterialPreview.unity}`
                                            )}`}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="flex-column">
                        <label htmlFor="label-fsdr-number">
                            {t("label-fsdr-number")}
                        </label>
                        <input
                            placeholder={t("label-fsdr-number")}
                            type="text"
                            {...register("fsdr.fsdrNumber", { required: true })}
                            value={watch("fsdr.fsdrNumber") || ""}
                            onChange={(e) => setValue("fsdr.fsdrNumber", e.target.value)}
                        />
                        {errors.fsdr?.fsdrNumber && <span>{t("error-input-required")}</span>}
                    </div>

                    {scrapDealerDetails && (
                        <div className="flex-column legal-informations margin-topbottom-1">
                            <p>
                                {t("label-scrap-dealer")}:{" "}
                                {scrapDealerDetails?.scrapDealerInfo?.legalName}
                            </p>
                            <p>
                                {scrapDealerDetails?.scrapDealerInfo?.nationalRegisterLabel}:{" "}
                                {scrapDealerDetails?.scrapDealerInfo?.nationalRegister}
                            </p>
                            <p>
                                {scrapDealerDetails?.addressInfo?.postalCodeLabel}:{" "}
                                {scrapDealerDetails?.addressInfo?.postalCode}
                            </p>
                            <p>{t("label-address")}:</p>
                            <p>
                                {scrapDealerDetails?.addressInfo?.address},{" "}
                                {scrapDealerDetails?.addressInfo?.addressNumber}
                            </p>
                            <p>
                                {scrapDealerDetails?.addressInfo?.city},{" "}
                                {scrapDealerDetails?.addressInfo?.state} -{" "}
                                {scrapDealerDetails?.addressInfo?.countryName}
                            </p>
                        </div>
                    )}
                    <div className="flex-column margin-topbottom-1">
                        <div className="flex-row justify-between">
                            <label>{t("label-documents")}</label>
                        </div>
                        <input
                            style={{ width: "96.5%" }}
                            type="file"
                            multiple
                            onChange={handleFilechange}
                        />
                        {selectedFiles?.length > 0 && (
                            <div>
                                {selectedFiles?.map((file: File, index: number) => (
                                    <label key={index}>{file.name || ""}</label>
                                ))}
                            </div>
                        )}
                        {watchedAttachmentfsdr &&
                            watchedAttachmentfsdr.map((url: string, index: number) => (
                                <SparkTableBody key={index}>
                                    <SparkTableRow onClick={() => window.open(url, "_blank")}>
                                        <SparkTableCell
                                            style={{ color: "var(--bosch-accent-blue)" }}
                                        >
                                            {extractFileNameFromUrl(url)}
                                        </SparkTableCell>
                                        <SparkTableCell
                                            style={{ justifyContent: "end" }}
                                            className="icon-column flex-row"
                                        >
                                            <SparkIcon
                                                pallete="tertiary"
                                                className="icon-flag"
                                                no-padding={true}
                                                icName={"download"}
                                            />
                                            <p style={{ color: "transparent" }}>-----</p>
                                        </SparkTableCell>
                                    </SparkTableRow>
                                </SparkTableBody>
                            ))}
                    </div>
                    <Expiration
                        dto={expiration}
                        dataHandler={handleDataChange}
                        expirationDate={"expirationDate"}
                        warningPeriod={"warningPeriod"}
                        hasExpirationDate={"hasExpirationDate"}
                        hasExpirationDateInit={expiration.hasExpirationDate || false}
                        expirationDateInit={expiration.expirationDate || ""}
                        warningPeriodInit={expiration.warningPeriod || item?.fsdr?.expirationDocument?.warningPeriod || 60 }
                          disableExpirationCheckbox
                    />

                    <div
                        className="flex-row justify-end"
                        style={{ gap: "10px", marginTop: "1.5rem" }}
                    >
                        <button onClick={onClose} style={{ margin: 0 }}>
                            {t("label-cancel")}
                        </button>
                        <button className="blue" type="submit" style={{ margin: 0 }}>
                            {t("label-save")}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NewFSDRModal;
