import { Doughnut } from "react-chartjs-2";
import ChartEmptyImg from "../../../../assets/img/chart-pie.svg";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { FinancialDTO } from "../../../../pages/financial/financial-DTO";
import { useEffect, useState } from "react";
import { formatCurrencyByLocale } from "../../../../utils/formatting";
import { hover } from "@testing-library/user-event/dist/hover";

ChartJS.register(ArcElement, Tooltip);

interface Props {
  data: FinancialDTO[] | null;
  idTofilter: any;
}

const FinancialDashboardGraphic: React.FC<Props> = ({ data, idTofilter }) => {
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    let tranlation = { d1: 0, d2: 0, d3: 0 };

    if (data) {
      if (idTofilter.label) {
        data = data.filter((obj) =>
          [idTofilter.label].includes(obj.scrapMaterialName)
        );
      }

      data.reduce((acc, obj) => {
        if (obj.status === "TO_COLLECT") {
          acc.d1 += obj.totalValue;
        } else if (obj.status === "TO_RECEIVE") {
          acc.d2 += obj.totalValue;
        } else if (obj.status === "RECEIVED") {
          acc.d3 += obj.totalValue;
        }
        return acc;
      }, tranlation);

      {formatCurrencyByLocale(tranlation.d1, "pt-BR", "BRL") }

      setFilteredData([tranlation.d1, tranlation.d2, tranlation.d3]);
    }
  }, [idTofilter]);

  const chartData = {
    labels: [],
    datasets: [
      {
        data: filteredData,
        backgroundColor: ["#9E2896", "#007BC0", "#18837E"],
        borderWidth: 1,
        hoverOffset: 0,
      },
    ],
  };

  if (!data) {
    return (
      <div className="chart-mock" style={{ minWidth: "300px", width: "100%" }}>
        <img src={ChartEmptyImg} alt="chat-empty" />
      </div>
    );
  }

  return <Doughnut data={chartData} />;
};

export default FinancialDashboardGraphic;
