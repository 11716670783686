import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../utils/firebase-config";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import { OptimizationInfoDTO,  OptimizationDTO } from "./models/optimization-DTO";
import { saveNewAsync, update } from "../../services/persistence";

// add new
export async function addNewOp(data: OptimizationDTO, id: string) {
  if (!data.optimizationInfo.id && !data.id) {
    data.optimizationInfo.originId = id;
    data.optimizationInfo.date = new Date().toLocaleString();
    saveNewAsync(COLLECTIONS.OPTIMIZATIONS, data.optimizationInfo)

  } else {
    update(COLLECTIONS.OPTIMIZATIONS, data.optimizationInfo, data.optimizationInfo.id ?? "");
  }
}

export async function getOptimizationById(id: string): Promise<OptimizationInfoDTO[]> {
  const optimizationCollectionRef = collection(firestore, COLLECTIONS.OPTIMIZATIONS);

  const q = query(optimizationCollectionRef, where("originId", "==", id));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    
    ...doc.data(),
    id: doc.id,

    searchData: doc
    .data()
    ["date"].concat(
      " ",
      doc.data()["optimizations"]
    ),
  })) as unknown as OptimizationInfoDTO[];
  return items;
}



export async function getOpti(id: string): Promise<OptimizationInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.OPTIMIZATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as OptimizationInfoDTO;
}

export async function getOptimizationRandom (originId: string){

  const data = await getOptimizationById(originId ?? "");

  const objetos = data;
  const indiceAleatorio = Math.floor(Math.random() * objetos.length);
  const objetoAleatorio = objetos[indiceAleatorio];
  return objetoAleatorio;
}
