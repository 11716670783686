import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import {
  callFunction,
  saveFiles,
  saveNewAsync,
  saveNewWithId,
  update,
} from "../../../../services/persistence";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { firestore } from "../../../../utils/firebase-config";
import {
  ObservationsDTO,
  PartnerRequirementDTO,
  PartnerRequirementInfoDTO,
} from "../../../settings/components/partners-requirements/partner-requirement-DTO";
import {
  PartnerRequirementDealerDTO,
  PartnerRequirementDealerTotalDTO,
} from "./partner-requirement-check-DTO";
import { ScrapDealerInfoDTO } from "../../scrap-dealer-DTO";
import { approvalStatusENUM } from "../../../../assets/enums/approval-status-enum";
import { setExpirationDocument } from "../../../../components/Expiration/ExpirationService";

// add new
export async function addNewPartnerRequirementDealerTotal(
  data: PartnerRequirementDealerTotalDTO,
  files: File[]
) {
  if (data.partnerRequirementDealerDTO.expirationDocument != null) {
    const originName = await getOriginName(
      data.partnerRequirementDealerDTO.dealerId
    );
    data.partnerRequirementDealerDTO.expirationDocument.origin = originName;
  }
  if (data.partnerRequirementDealerDTO.id == undefined) {
    await saveNewAsync(
      COLLECTIONS.PARTNER_REQUIREMENTS_DEALER,
      data.partnerRequirementDealerDTO
    ).then((docRef) => {
      saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
      if (files.length > 0) {
        saveFiles(
          COLLECTIONS.PARTNER_REQUIREMENTS_DEALER,
          docRef.id,
          COLLECTIONS.PARTNER_REQUIREMENTS_DEALER,
          files,
          "files"
        );
      }
      if (data.partnerRequirementDealerDTO.expirationDocument != null) {
        setExpirationDocument(
          data.partnerRequirementDealerDTO.expirationDocument,
          docRef.id
        );
      }
    });
  } else {
    await update(
      COLLECTIONS.PARTNER_REQUIREMENTS_DEALER,
      data.partnerRequirementDealerDTO,
      data.partnerRequirementDealerDTO.id
    );
    await update(
      COLLECTIONS.OBSERVATIONS,
      data.observations,
      data.partnerRequirementDealerDTO.id
    );
    if (files.length > 0) {
      await saveFiles(
        COLLECTIONS.PARTNER_REQUIREMENTS_DEALER,
        data.partnerRequirementDealerDTO.id,
        COLLECTIONS.PARTNER_REQUIREMENTS_DEALER,
        files,
        "files"
      );
    }
    if (data.partnerRequirementDealerDTO.expirationDocument != null) {
      await setExpirationDocument(
        data.partnerRequirementDealerDTO.expirationDocument,
        data.partnerRequirementDealerDTO.id
      );
    }
  }
  await callFunction("updatehomologationstatus", {id:data.partnerRequirementDealerDTO.dealerId}, data.id);
}

async function getOldStatus(id: string): Promise<string> {
  const docRef = doc(firestore, COLLECTIONS.PARTNER_REQUIREMENTS_DEALER, id);
  const docSnap = await getDoc(docRef);

  const partnerRequirementDealer =
    docSnap.data() as PartnerRequirementDealerDTO;
  return partnerRequirementDealer.sdmApprovalStatus;
}

async function getOriginName(id: string): Promise<string> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_DEALERS, id);
  const docSnap = await getDoc(docRef);

  const dealer = docSnap.data() as ScrapDealerInfoDTO;
  return dealer.tradingName;
}

export async function getPartnerRequirementDealer(
  dealerId: string
): Promise<PartnerRequirementDealerDTO[]> {
  const collectionRefDealer = collection(
    firestore,
    COLLECTIONS.PARTNER_REQUIREMENTS_DEALER
  );
  const q = query(collectionRefDealer, where("dealerId", "==", dealerId));
  const querySnapshotDealer = await getDocs(q);

  const itemsDealer = querySnapshotDealer.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as PartnerRequirementDealerDTO[];

  return itemsDealer;
}

export async function getPartnerRequirementbyIdDealer(
  requirementId: string,
  dealerId: string
): Promise<PartnerRequirementDealerDTO> {
  const collectionRefDealer = collection(
    firestore,
    COLLECTIONS.PARTNER_REQUIREMENTS_DEALER
  );
  const q = query(
    collectionRefDealer,
    where("dealerId", "==", dealerId),
    where("requirementId", "==", requirementId)
  );
  const querySnapshotDealer = await getDocs(q);

  const itemsDealer = querySnapshotDealer.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as PartnerRequirementDealerDTO[];

  return itemsDealer[0];
}

export const getById = async (
  id: string
): Promise<PartnerRequirementDTO | null> => {
  const partnerRequirementInfoDTO = await getInfoById(id);
  const observations = await getObservation(id);

  return {
    id,
    partnerRequirementInfoDTO,
    observations,
  };
};

export async function getInfoById(
  id: string
): Promise<PartnerRequirementInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.PARTNER_REQUIREMENTS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as PartnerRequirementInfoDTO;
}

export async function getObservation(id: string): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}
