import { useTranslation } from "react-i18next";
import addIcon from "../../../../assets/svgs/add.svg";
import CardListUser from "./cardlist-users/cardlist-user";
import { useEffect, useState } from "react";
import UserForm from "./users-form/user-form";
import { UserInfoDTO } from "./models/users-DTO";
import { getUsersById } from "./users-service";
import { useNavigate, useParams } from "react-router-dom";
import ImportExportCsv from "./../../../../components/ImportExportCsv/ImportExportCsv";
import { useAuth } from "../../../../contexts/auth-context";
import FilterComponent from "../../../../components/FIlter/filter";

// Funções de ordenação
const sortByUserName = (data: UserInfoDTO[]) => {
  return [...data].sort((a, b) => {
    const nameA = a.name?.toString().toLowerCase() || '';
    const nameB = b.name?.toString().toLowerCase() || '';
    return nameA.localeCompare(nameB);
  });
};

const sortByUserEmail = (data: UserInfoDTO[]) => {
  return [...data].sort((a, b) => {
    const emailA = a.email?.toString().toLowerCase() || '';
    const emailB = b.email?.toString().toLowerCase() || '';
    return emailA.localeCompare(emailB);
  });
};
const sortByacess = (data: UserInfoDTO[]) => {
  return [...data].sort((a, b) => {
    const acessA = a.lastAccess?.toString().toLowerCase() || '';
    const acessB = b.lastAccess?.toString().toLowerCase() || '';
    return acessA.localeCompare(acessB);
  });
};
const sortprofile = (data: UserInfoDTO[]) => {
  return [...data].sort((a, b) => {
    const perfilA = a.profile?.toString().toLowerCase() || '';
    const perfilB = b.profile?.toString().toLowerCase() || '';
    return perfilA.localeCompare(perfilB);
  });
};


const Users: React.FC<{ exportApi?: string, importApi?: string, enabledUsers: string[] }> = ({ enabledUsers, importApi, exportApi }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState<UserInfoDTO[]>([]);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [filteredData, setFilteredData] = useState<UserInfoDTO[]>([]);

  
  const handleFilterClick = (filteredData: UserInfoDTO[]) => {
    setFilteredData(filteredData);
  };

  const sortByOptions = [
    { label: t("label-name"), sortFunction: sortByUserName },
    { label: t("label-email"), sortFunction: sortByUserEmail },
    { label: t("label-last-login"), sortFunction: sortByacess },
    { label: t("label-profile"), sortFunction: sortprofile }
  ];

  const handleSortClick = (sortFunction: (data: UserInfoDTO[]) => UserInfoDTO[]) => {
    const sortedData = sortFunction([...filteredData]);
    setFilteredData(sortedData);
  };

  const fetchUsers = async () => {
    if (id) {
      try {
        const data = await getUsersById(id);
        setUsers(data);
        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching users: ", error);
      }
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [id]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchUsers(); // Atualizar a lista de usuários ao fechar o modal
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <UserForm userId="" onClose={handleCloseModal} />
          </div>
        )}
        <div className="page">
          <div className="flex-row">
            <button className="blue-with-icon button" onClick={handleOpenModal}>
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
            <ImportExportCsv
              exportApi={exportApi}
              importApi={importApi}
              onClose={handleCloseModal}
            />
            <FilterComponent
              data={users}
              onFilterClick={handleFilterClick}
              sortOptions={sortByOptions}
           
            />
          </div>
          <br />
          <CardListUser onClose={handleCloseModal} users={filteredData} />
        </div>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default Users;
