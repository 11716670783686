import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrapDealerDTO } from "../../scrap-dealer-DTO";
import { getScrapDealerById } from "../../scrap-dealers-service";
import { useAuth } from "../../../../contexts/auth-context";
import { getVehiclesById } from "../../../vehicles/vehicles-service";
import { getDriversById } from "../../../drivers/drivers-service";
import { ChecklistItem } from "../../../../components/ChecklistComponent/models/checklist-item-DTO";
import ChecklistGenericComponent from "../../../../components/ChecklistComponent/ChecklistComponent";

const ScrapDealerDetailsRegister: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [scrapDealer, setScrapDealer] = useState<ScrapDealerDTO | null>(null);
  const [checklistItems, setChecklistItems] = useState<ChecklistItem[]>([]);
  const [allCompleted, setAllCompleted] = useState<boolean>(true);

  const fetchChecklistItems = async () => {
    if (id) {
      const checklistData: ChecklistItem[] = [];

      const addChecklistItem = (item: ChecklistItem) => {
        const exists = checklistData.some((i) => i.text === item.text);
        if (!exists) {
          checklistData.push(item);
        }
      };

      await getVehiclesById(id).then((vehicles) => {
        addChecklistItem({
          text: t("label-vehicles-complete-register"),
          link: `/scrap-dealers-details/${id}/vehicles`,
          checked: vehicles.length > 0,
        });
      });

      await getDriversById(id).then((drivers) => {
        addChecklistItem({
          text: t("label-complete-register-drivers"),
          link: `/scrap-dealers-details/${id}/drivers`,
          checked: drivers.length > 0,
        });
      });

      await getScrapDealerById(id).then((dealer) => {
        addChecklistItem({
          text: t("label-complete-fullfilled-requirements"),
          link: `/scrap-dealers-details/${id}/requirement-check`,
          checked: dealer?.scrapDealerInfo.homologationStatus == "VALIDATED",
        });
      });

      setChecklistItems(checklistData);
      setAllCompleted(checklistData.every((item) => item.checked));
    }
  };

  const fetchScrapDealer = async () => {
    if (!id) return;
    try {
      const data = await getScrapDealerById(id);
      if (data) {
        setScrapDealer(data);
      } else {
        console.error("Data is undefined");
      }
    } catch (error) {
      console.error("Error fetching scrap dealer:", error);
    }
  };

  useEffect(() => {
    fetchChecklistItems();
    fetchScrapDealer();
  }, [id]);

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="register-tab">
        <ChecklistGenericComponent
          title={t("label-complete-register-to-operate")}
          items={checklistItems}
        ></ChecklistGenericComponent>
        <div
          className="flex-row edit-section"
          style={{ justifyContent: "space-between" }}
        >
          <p
            style={{
              color: "var(--bosch-accent-turquoise)",
              fontWeight: "semibold",
            }}
          >
            {allCompleted ? t("label-completed-register") : " "}
          </p>
          <a onClick={() => navigate(`/scrap-dealers-edit/${scrapDealer?.id}`)}>
            {t("label-edit")}
          </a>
        </div>

        {scrapDealer?.scrapDealerInfo.headquarterId && (
          <div className="info-section">
            <div className="flex-row title">
              <h3>{t("label-headquarter")}</h3>
              <a
                onClick={() =>
                  navigate(
                    `/scrap-dealers-details/${scrapDealer?.scrapDealerInfo.headquarterId}/register`
                  )
                }
              >
                {t("label-view")}
              </a>
            </div>
            <div className="flex-column">
              <label>{t("label-headquarter-name")}</label>
              <input
                value={scrapDealer?.scrapDealerInfo.headquarterName || "-"}
                disabled={true}
              />
            </div>
          </div>
        )}

        <div className="info-section">
          <h3>{t("label-business-information")}</h3>
          <div className="flex-column">
            <label>{t("label-legal-name")}</label>
            <input
              value={scrapDealer?.scrapDealerInfo.legalName || "-"}
              disabled={true}
            />
          </div>
          <div className="flex-column">
            <label>{t("label-trading-name")}</label>
            <input
              value={scrapDealer?.scrapDealerInfo.tradingName || "-"}
              disabled={true}
            />
          </div>
          <div className="flex-column">
            <label>
              {scrapDealer?.scrapDealerInfo.nationalRegisterLabel ||
                t("label-national-register")}
            </label>
            <input
              value={scrapDealer?.scrapDealerInfo.nationalRegister || "-"}
              disabled={true}
            />
          </div>
          <div className="flex-column">
            <label>{t("label-taxation-type")}</label>
            <input
              value={
                t(
                  `taxationTypesENUM.${scrapDealer?.scrapDealerInfo.taxationType}`
                ) || "-"
              }
              disabled={true}
            />
          </div>

          <div className="flex-column">
            <label>{t("label-segment")}</label>
            <input
              value={scrapDealer?.scrapDealerInfo.segment || "-"}
              disabled={true}
            />
          </div>

          {scrapDealer?.addressInfo?.countryName === "Brasil" && (
            <div className="flex-column">
              <label>{t("label-business-mtr-code")}</label>
              <input
                value={scrapDealer?.scrapDealerInfo.mtrCode || "-"}
                disabled={true}
              />
            </div>
          )}
        </div>

        <div className="info-section">
          <h3>{t("label-location")}</h3>
          <div className="flex-column">
            <label>
              {scrapDealer?.addressInfo.postalCodeLabel ||
                t("label-postal-code")}
            </label>
            <input
              value={scrapDealer?.addressInfo.postalCode || "-"}
              disabled={true}
            />
          </div>

          <div className="flex-column">
            <label>{t("label-address")}</label>
            <input
              value={scrapDealer?.addressInfo.address || "-"}
              disabled={true}
            />
          </div>

          <div className="double-fields">
            <div className="flex-column">
              <label>{t("label-city")}</label>
              <input
                value={scrapDealer?.addressInfo.city || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-state")}</label>
              <input
                value={scrapDealer?.addressInfo.state || "-"}
                disabled={true}
              />
            </div>
          </div>
        </div>

        <div className="info-section">
          <h3>{t("label-contact")}</h3>
          <div className="flex-column">
            <label>{t("label-contact-name")}</label>
            <input
              value={scrapDealer?.contactInfo.name || "-"}
              disabled={true}
            />
          </div>
          <div className="double-fields">
            <div className="flex-column">
              <label>{t("label-email")}</label>
              <input
                value={scrapDealer?.contactInfo.email || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-phone")}</label>
              <input
                value={scrapDealer?.contactInfo.phone || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-function")}</label>
              <input
                value={scrapDealer?.contactInfo.function || "-"}
                disabled={true}
              />
            </div>
          </div>
        </div>

        <div className="info-section">
          <h3>{t("label-general-observations")}</h3>
          <div>
            <textarea
              disabled
              value={scrapDealer?.observations.observations || "-"}
            ></textarea>
          </div>
        </div>

        <div className="flex-row buttons-section">
          <button
            onClick={() => {
              navigate("/scrap-dealers");
            }}
            className="neutral-white"
          >
            {t("label-back")}
          </button>
        </div>
        <br />
        <br />
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapDealerDetailsRegister;
