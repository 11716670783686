import React from "react";

interface PdfViewerProps {
  pdfUrl: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ pdfUrl }) => {
  return (
    <iframe
      src={pdfUrl}
      width="100%"
      height="100%"
      style={{ border: "none" }}
      title="PDF Viewer"
    />
  );
};

export default PdfViewer;
