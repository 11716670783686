import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../../contexts/alert-context";
import { ExpirationDTO } from "../../../../components/Expiration/models/ExpirationDTO";
import Expiration from "../../../../components/Expiration/Expiration";
import { saveNewWithId, updateAsync } from "../../../../services/persistence";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { getDriverAuthorizationById } from "../../drivers-service";
import {
  formatDateToString,
  formatDateToStringYearFirst,
} from "../../../../services/date-format";

interface DriverModalDriverAuthorizationProps {
  driverId: string;
  generatorId: string;
  generatorName: string;
  driverName: string;
  onClose: () => void;
}

const DriverModalDriverAuthorization: React.FC<
  DriverModalDriverAuthorizationProps
> = ({ driverId, generatorId, generatorName, driverName, onClose }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();

  const [authorizationDate, setAuthorizationDate] = useState<string>("");
  const [expiration, setExpiration] = useState<ExpirationDTO>({
    originId: "",
    docName: "Driver License",
    expirationDate: "",
    warningPeriod: 30,
    solved: false,
    hasExpirationDate: false,
    origin: "",
  });

  const handleDataChange = (key: string, value: any) => {
    setExpiration((prevData: ExpirationDTO) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const dateAuthorization = new Date(authorizationDate);
      dateAuthorization.setDate(dateAuthorization.getDate() + 1);

      const dataToSave = {
        expirationDocument: expiration,
        autorizationDate: dateAuthorization,
      };
      const authorizationId = `${driverId}${generatorId}`;
      await saveNewWithId(
        COLLECTIONS.DRIVER_AUTORIZATION,
        {...dataToSave, tenantId: generatorId},
        authorizationId
      );

      setAlert({ text: t("alert-successfully-updated"), type: "success" });
      onClose();
    } catch (error) {
      console.error("Erro ao salvar: ", error);
      setAlert({ text: t("alert-error-registered"), type: "error" });
    }
  };

  useEffect(() => {
    getDriverAuthorizationById(`${driverId}${generatorId}`).then(
      (driverAutorization) => {
        if (driverAutorization) {
          setAuthorizationDate(
            formatDateToStringYearFirst(driverAutorization?.autorizationDate)
          );
          setExpiration(driverAutorization.expirationDocument);
        }
      }
    );
  }, []);

  return (
    <div className="modal-content" style={{ gap: 10 }}>
      <h2>{t("label-autorization-driver")}</h2>
      <div className="flex-column" style={{ gap: 10 }}>
        <label htmlFor="driverName">{`${t("label-name")}`}</label>
        <input
          placeholder={t("placeholder.generalEmpty")}
          disabled
          type="text"
          id="driverName"
          value={driverName}
        />
      </div>
      <div className="flex-column" style={{ gap: 10 }}>
        <label htmlFor="generatorName">{`${t("label-generator")}`}</label>
        <input
          placeholder={t("placeholder.generalEmpty")}
          disabled
          type="text"
          id="generatorName"
          value={generatorName}
        />
      </div>
      <div className="flex-column" style={{ gap: 10 }}>
        <label htmlFor="authorizationDate">{`${t(
          "label-autorizationDate"
        )}*`}</label>
        <input
          type="date"
          id="authorizationDate"
          value={authorizationDate}
          onChange={(e) => setAuthorizationDate(e.target.value)}
          placeholder={t("placeholder.generalEmpty")}
        />
      </div>
      <Expiration
        dto={expiration}
        dataHandler={handleDataChange}
        expirationDate={"expirationDate"}
        warningPeriod={"warningPeriod"}
        hasExpirationDate={"hasExpirationDate"}
        hasExpirationDateInit={expiration.hasExpirationDate}
        expirationDateInit={expiration.expirationDate}
        warningPeriodInit={expiration.warningPeriod}
      />
      <div className="flex-row buttons-section" style={{ gap: 10 }}>
        <button className="neutral-white" onClick={onClose}>
          {t("label-cancel")}
        </button>
        <button
          disabled={authorizationDate == null || authorizationDate == ""}
          className="blue"
          onClick={handleSave}
        >
          {t("label-save")}
        </button>
      </div>
    </div>
  );
};

export default DriverModalDriverAuthorization;
