import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../utils/firebase-config";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import { ContactInfoDTO, ContactsDTO, ObservationsDTO } from "./contacts-DTO";
import { saveNewAsync, saveNewWithId, update } from "../../services/persistence";

// add new
export async function addNewContact(data: ContactsDTO, id: string) {
  if (data.id == undefined) {
    data.contactInfoDTO.originId = id;
    saveNewAsync(COLLECTIONS.CONTACTS, {...data.contactInfoDTO, tenantId: data.contactInfoDTO.originId}).then((docRef) => {
      if(data.observations){
        saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
      }
    });
  } else {
    update(COLLECTIONS.CONTACTS, data.contactInfoDTO, data.id);
    if(data.observations){
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
    }
  }
}

export async function getContactsById(id: string): Promise<ContactInfoDTO[]> {
  const contactsCollectionRef = collection(firestore, COLLECTIONS.CONTACTS);

  const q = query(contactsCollectionRef, where("originId", "==", id));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc
    .data()
    ["name"].concat(
      " ",
      doc.data()["email"],
      " ",
      doc.data()["type"]
    ),
  })) as unknown as ContactInfoDTO[];
  return items;
}

export const getContactById = async (
  id: string
): Promise<ContactsDTO | null> => {
  const contactInfoDTO = await getContact(id);
  const observations = await getContactObservation(id);

  return {
    id,
    contactInfoDTO,
    observations,
  };
};

export async function getContact(id: string): Promise<ContactInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.CONTACTS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ContactInfoDTO;
}

export async function getContactObservation(
  id: string
): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}
